import PropTypes from 'prop-types';
import * as Select from '@radix-ui/react-select';
import {useRouter} from 'next/router';
import {useRef, useMemo, useContext, useState, createContext} from 'react';

import useSelectLogoContext from './SelectLogo.context';
import useIndustries from '../../../../hooks/useIndustries';

import {IcChevronExpand, IcCheck01} from '../../../UI/Atoms/Icons';
import Text from '../../../UI/Atoms/Typography/Text';

import * as Accordion from '../../../UI/Accordion';
import Backdrop from '../Common/Backdrop';
import MobileDropDownHeader from '../Common/MobileDropDownHeader';
import useWindowDimensions from '../../../../hooks/wizard/useWindowDimensions';
import {breakpoints} from '../../../../lib/constants';

const updateIndustryParams = (router, industry) => {
  const {query} = router;
  const newQuery = {
    ...query,
    industry: industry.fTag,
    industryId: industry.fTag,
    industryName: industry.name,
  };

  const newUrl = new URL(window.location.pathname.split('/')[1], window.location.origin);
  const cleanUrl =
    industry.name === 'All Logos' ? '' : industry.name.toLowerCase().split(' ').join('-');

  router.push(
    {
      pathname: router.pathname,
      query: newQuery,
    },
    `${newUrl.href}/${cleanUrl}`,
    {shallow: true}
  );
};

const IndustrySelectorContext = createContext({
  accordionOpenStates: [],
});

function BaseItem({industry, className, ...props}) {
  return (
    <Select.Item
      key={industry.id}
      value={industry.id}
      className={`flex items-center justify-between px-6 py-1.5 outline-none hover:cursor-pointer md:px-3 md:py-1 [&[data-highlighted]]:bg-cold-gray-200 ${className}`}
      {...props}
    >
      <Select.ItemText>
        <Text size="lg" className="text-cold-gray-700 md:text-sm">
          {industry.label}
        </Text>
      </Select.ItemText>
      <Select.ItemIndicator>
        <IcCheck01 className="h-3.5 w-3.5 text-cold-gray-700" />
      </Select.ItemIndicator>
    </Select.Item>
  );
}

BaseItem.defaultProps = {
  className: '',
};

BaseItem.propTypes = {
  industry: PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

function IndustryGroup({industry}) {
  const accordionTriggerRef = useRef(null);

  const {accordionOpenStates} = useContext(IndustrySelectorContext);

  const onKeyDown = e => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      accordionTriggerRef.current.querySelector('button').click();

      e.preventDefault();
      e.stopPropagation();
    }
  };

  const preventClickEvent = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Accordion.Item value={industry.id} className="[&[data-state='open']]:bg-cold-gray-100">
      {/**
        We need to wrap the accordion trigger in a Select.Item so that it can be focused with the keyboard.
        Also, due to this "items" can't be selected, we need to prevent the following events:
        - click (to prevent the Radix Select from closing and selecting the item)
        - keydown for Enter (key code 13) and Space (key code 32) (to prevent the Radix Select from closing and selecting the item)
      */}
      <Select.Item
        className="flex w-full items-center justify-between outline-none hover:cursor-pointer [&[data-highlighted]]:bg-cold-gray-200"
        onClick={preventClickEvent}
        onPointerUp={preventClickEvent}
        onKeyDown={onKeyDown}
      >
        <div ref={accordionTriggerRef}>
          <Accordion.Header className="flex items-center justify-between px-2 py-1 outline-none hover:cursor-pointer [&[data-highlighted]]:bg-cold-gray-200 [&[data-state='open']_#accordion-icon]:rotate-0">
            <span id="accordion-icon" className="-rotate-90 transition-transform duration-300">
              <IcChevronExpand className="h-4 w-4 text-cold-gray-700" />
            </span>
            <Text size="lg" className="text-cold-gray-700 md:text-sm">
              {industry.label}
            </Text>
          </Accordion.Header>
        </div>
      </Select.Item>
      <Accordion.Content className="[&[data-state='closed']]:h-0" forceMount>
        {industry.children.map(child => (
          <BaseItem
            key={child.id}
            industry={child}
            className="pl-8"
            disabled={accordionOpenStates.includes(industry.id) === false}
          />
        ))}
      </Accordion.Content>
    </Accordion.Item>
  );
}

IndustryGroup.propTypes = {
  industry: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

function IndustrySelector() {
  const router = useRouter();
  const {selectedIndustry, setSelectedIndustry} = useSelectLogoContext();
  const {industries} = useIndustries();
  const screen = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [accordionOpenStates, setAccordionOpenStates] = useState([]);
  const contextValue = useMemo(
    () => ({
      accordionOpenStates: [...accordionOpenStates],
    }),
    [accordionOpenStates]
  );

  const triggerRef = useRef(null);
  const contentRef = useRef(null);

  // When open, make the content apper below the trigger aligned to the left
  const handleOpenChange = open => {
    setIsOpen(open);
    if (!open) return;

    // Grant that the container of the selected industry is open (if any)
    if (selectedIndustry?.parent_id) {
      setAccordionOpenStates([selectedIndustry.parent_id]);
    }

    const triggerWidth = triggerRef.current.offsetWidth;
    const triggerPosition = triggerRef.current.getBoundingClientRect();
    const triggerX = triggerPosition.x;
    const triggerY = triggerPosition.y;
    const triggerHeight = triggerPosition.height;

    // Set content bellow trigger
    contentRef.current.style.top = `${triggerY + triggerHeight + 4}px`;
    contentRef.current.style.left = `${triggerX}px`;
    contentRef.current.style.width = `${triggerWidth}px`;

    // Media Query
    if (screen.width < breakpoints.md) {
      contentRef.current.style.top = `25%`;
      contentRef.current.style.left = `0px`;
      contentRef.current.style.width = `${screen.width}px`;
    }
  };

  const handleIndustryChange = industryId => {
    const newSelectedIndustry = industries.find(ind => ind.id === Number(industryId));
    setSelectedIndustry(newSelectedIndustry);
    updateIndustryParams(router, newSelectedIndustry);
  };

  const industryOptions = useMemo(() => {
    if (industries.length === 1) {
      return industries;
    }

    const withoutContainers = industries.filter(ind => !ind.isContainer && ind.parent_id === null);
    const mapContainers = industries.reduce((acc, ind) => {
      if (ind.isContainer) {
        acc[ind.id] = {...ind, children: []};
      }
      return acc;
    }, {});

    industries.forEach(ind => {
      if (!ind.isContainer && ind.parent_id) {
        if (ind.label.includes('(All)')) {
          mapContainers[ind.parent_id].children.unshift(ind);
        } else {
          mapContainers[ind.parent_id].children.push(ind);
        }
      }
    });

    Object.keys(mapContainers).forEach(cont => {
      mapContainers[cont].children.sort((a, b) => {
        if (a.label.includes('(All)')) return -1;
        if (b.label.includes('(All)')) return 1;
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    });

    const temp = [...withoutContainers, ...Object.values(mapContainers)];

    temp.sort((a, b) => {
      if (a.name === 'All Logos') return -1;
      if (b.name === 'All Logos') return 1;
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return temp;
  }, [industries, selectedIndustry]);

  return (
    <Select.Root
      value={selectedIndustry.id}
      defaultValue={selectedIndustry.id}
      onOpenChange={handleOpenChange}
      onValueChange={handleIndustryChange}
    >
      <Select.Trigger
        className="flex h-9 w-full items-center justify-between rounded-md border-1 border-cold-gray-400 bg-white px-3 py-2 text-left outline-none hover:border-cold-gray-600 focus:border-cold-gray-800 [&[data-state='open']_#trigger-icon]:rotate-180"
        ref={triggerRef}
      >
        <Text size="md" className="text-cold-gray-800">
          <Select.Value />
        </Text>
        <Select.Icon id="trigger-icon" className="origin-center transition-transform duration-300">
          <IcChevronExpand className="h-4 w-4" />
        </Select.Icon>
      </Select.Trigger>
      {isOpen && screen.width < breakpoints.md && <Backdrop />}
      <Select.Portal>
        <Select.Content
          className="fixed z-40 h-[75vh] animate-slideUp rounded-t-md border-1 bg-white py-2 md:h-96 md:animate-dropDown md:rounded-md"
          ref={contentRef}
        >
          {screen.width < breakpoints.md && <MobileDropDownHeader title="Choose Industry" />}
          <Select.Viewport>
            <Accordion.Root
              collapsible
              type="multiple"
              defaultValue={[selectedIndustry.parent_id]}
              onValueChange={setAccordionOpenStates}
            >
              <IndustrySelectorContext.Provider value={contextValue}>
                {industryOptions.map(industry => {
                  if (industry.isContainer) {
                    return <IndustryGroup key={industry.id} industry={industry} />;
                  }
                  return <BaseItem key={industry.id} industry={industry} className="" />;
                })}
              </IndustrySelectorContext.Provider>
            </Accordion.Root>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
}

export default IndustrySelector;
