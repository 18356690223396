import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import {forwardRef} from 'react';

import {PreviewStatuses} from './Merch.machine';

const ProcessingOverlay = dynamic(() => import('../Steps/Common/ProcessingOverlay'), {ssr: false});

const Item = forwardRef(({className, itemData, ...props}, ref) => {
  const showImage =
    itemData.previewStatus === PreviewStatuses.IDLE ||
    itemData.previewStatus === PreviewStatuses.PROCESSING;
  const showOverlay = itemData.previewStatus === PreviewStatuses.PROCESSING;
  const hideElementCompletely = itemData.previewStatus === PreviewStatuses.FAILED;

  return (
    <div
      ref={ref}
      className={`${className} overflow-hidden rounded-md bg-transparent ${
        hideElementCompletely ? 'absolute -z-10' : 'relative z-0'
      }`}
      {...props}
    >
      {showImage && (
        <img src={itemData.image_url} alt={itemData.title} className="h-full w-full object-cover" />
      )}
      <div
        className={`absolute inset-0 h-full w-full overflow-hidden rounded-md ${
          showImage ? 'invisible' : 'visible'
        }`}
        data-v5-target
      />
      {showOverlay && <ProcessingOverlay />}
    </div>
  );
});

Item.displayName = 'Merch Item';

Item.defaultProps = {
  className: '',
};

Item.propTypes = {
  className: PropTypes.string,
  itemData: PropTypes.shape({
    title: PropTypes.string,
    image_url: PropTypes.string,
    previewStatus: PropTypes.oneOf(Object.values(PreviewStatuses)),
  }).isRequired,
};

export default Item;
