import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {createContext, useContext, useMemo, useEffect} from 'react';

import {useMachine} from '@xstate/react';

import {useWizardContext} from '../../../UI/Organisms/Wizard/Wizard';
import createSelectLogoMachine from './SelectLogo.machine';
import Industry from '../../../../lib/placeit/models/industry';
import {DEFAULT_INDUSTRY} from '../../../../lib/placeit/services/industries';

const SelectLogoContext = createContext({});

export default function useSelectLogoContext() {
  return useContext(SelectLogoContext);
}

export function SelectLogoProvider({children, initialIndustry, initialLogos}) {
  const router = useRouter();
  const {goFirst} = useWizardContext();

  const machine = useMemo(
    () => createSelectLogoMachine(initialIndustry, router?.query?.search || '', initialLogos),
    []
  );
  const [state, sendEvent] = useMachine(machine);

  const {
    items,
    companyName,
    selectedLogo,
    selectedIndustry,
    selectedGraphic,
    fetching,
    sortBy,
    minimalUI,
  } = state.context;

  const contextValue = useMemo(() => {
    return {
      fetching,
      items,
      companyName,
      selectedLogo,
      selectedIndustry,
      selectedGraphic,
      sortBy,
      minimalUI,
      sendEvent,

      setCompanyName: value => sendEvent({type: 'SET_COMPANY_NAME', value}),
      setSelectedLogo: value => sendEvent({type: 'SET_SELECTED_LOGO', value}),
      setSelectedIndustry: value => sendEvent({type: 'SET_SELECTED_INDUSTRY', value}),
      setSelectedGraphic: value => sendEvent({type: 'SET_SELECTED_GRAPHIC', value}),
      setSortBy: value => sendEvent({type: 'SET_SORT_BY', value}),

      showMoreClicked: () => sendEvent({type: 'SHOW_MORE_CLICKED'}),
      fetchMore: () => sendEvent({type: 'FETCH_MORE'}),
    };
  }, [state.context]);

  // React to query changes
  useEffect(() => {
    if (router?.query?.search) {
      goFirst();
      sendEvent({type: 'SET_QUERY', value: router.query.search});
    }
  }, [router?.query.search]);

  return <SelectLogoContext.Provider value={contextValue}>{children}</SelectLogoContext.Provider>;
}

SelectLogoProvider.defaultProps = {
  children: null,
  initialIndustry: DEFAULT_INDUSTRY,
  initialLogos: null,
};

SelectLogoProvider.propTypes = {
  children: PropTypes.node,
  initialIndustry: PropTypes.shape(Industry),
  initialLogos: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
