import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import Text from '../../../UI/Atoms/Typography/Text';

const IcCloseSm = dynamic(() => import('../../../UI/Atoms/Icons/IcCloseSm'), {ssr: false});

export default function MobileContentHeader({title}) {
  const onClick = e => {
    e.preventDefault();
    const keyboardEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      bubbles: true,
    });
    e.target.dispatchEvent(keyboardEvent);
  };
  return (
    <div className="flex flex-row p-2">
      <Text size="xl" className="w-full">
        {title}
      </Text>
      <button type="button" onClick={onClick} className="">
        <IcCloseSm className="mb-1 h-6 w-6" />
      </button>
    </div>
  );
}
MobileContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
