import PropTypes from 'prop-types';
import {useEffect, useRef, useState, useCallback} from 'react';
import {useMachine} from '@xstate/react';

import useLogoMakerContext from '../LogoMaker.context';
import useNotifications from '../../../hooks/useNotifications';

import Text from '../../UI/Atoms/Typography/Text';
import Item from './Item';
import ErrorScreen from './ErrorScreen';
import SkeletonItem from './SkeletonItem';
import TooltipNotification from './TooltipNotification';
import {IcChevronExpand} from '../../UI/Atoms/Icons';

import useWindowDimensions from '../../../hooks/wizard/useWindowDimensions';
import {breakpoints} from '../../../lib/constants';

import createMerchMachine, {PreviewStatuses} from './Merch.machine';

// TODO: Create a service and a model for this
async function getMergeByStageId(stageId) {
  const res = await fetch(`/stages/related_templates/${stageId}`);
  return res.json();
}

const machine = createMerchMachine();

const roundedBorders =
  "lg:before:hidden before:absolute before:left-[-8px] before:bottom-0 before:block before:h-[9px] before:w-[9px] before:bg-[radial-gradient(circle_8px_at_0_0,_transparent_0%,_transparent_96%,_white_100%)] before:content-[''] " +
  "lg:after:hidden after:absolute after:right-[-8px] after:bottom-0 after:block after:h-[9px] after:w-[9px] after:bg-[radial-gradient(circle_8px_at_100%_0,_transparent_0%,_transparent_96%,_white_100%)] after:content-['']";

function MerchContainer({wide, onTabClick, isTabOpen}) {
  const windowDimensions = useWindowDimensions();
  const [isMobileDevice, setIsMobileDevice] = useState(windowDimensions.width < breakpoints.lg);

  // TODO: Memoize this
  const itemsContainerClasses = wide
    ? 'grid-cols-2 py-5 px-12'
    : 'grid-cols-4 py-2.5 px-6 lg:grid-cols-1 lg:p-5 lg:auto-rows-min';

  // TODO: Memoize this
  const itemsHeight = wide ? 'h-56' : 'h-[73px] w-[72px] lg:w-[156px] lg:h-[184px]';

  const {
    logo,
    fetchingUiJSON,
    lastSelectedPreview,
    getUiJSON,
    colors,
    activeStep,
    isMerchHidden,
    setIsMerchHidden,
  } = useLogoMakerContext();
  const Notification = useNotifications();

  const [state, send] = useMachine(machine, {
    services: {
      fetchItems: async () => {
        try {
          const data = await getMergeByStageId(logo.id);

          if (data.status === 404) {
            throw new Error('An error occurred while loading the merch items.');
          }

          // Get only the first 4 items
          return {items: data.productshots.slice(0, 4)};
        } catch (error) {
          throw new Error('An error occurred while loading the merch items.');
        }
      },
      processPreviews: async ctx => {
        const {svgEngine, preview, backgroundColor} = ctx;
        const promises = svgEngine.renderChanges(preview, backgroundColor).map(res => res());

        return Promise.allSettled(promises);
      },
    },
    actions: {
      showError: () => {
        Notification.danger({
          title: 'Something went wrong',
          message: 'An error occurred while processing the merch items.',
        });
      },
    },
  });
  const {merch} = state.context;

  const isMerchEmpty = merch.length === 0;
  const merchTitleRef = useRef(null);

  useEffect(() => {
    setIsMobileDevice(windowDimensions.width < breakpoints.lg);
  }, [windowDimensions]);

  useEffect(() => {
    send('RECREATE');
  }, [isMobileDevice]);

  useEffect(() => {
    if (merch.length > 0) {
      setIsMerchHidden(false);
    }
  }, [merch]);

  useEffect(() => {
    if (logo) {
      // TODO: Improve the way of extracting the backgroundColor
      const uiJSON = getUiJSON();
      if (!uiJSON) return;

      const backgroundColor = uiJSON?.backgroundColor.color;

      send('NEW LOGO', {logo, preview: lastSelectedPreview, backgroundColor});
    } else {
      send('CLEAR LOGO');
    }
  }, [logo, fetchingUiJSON]);

  useEffect(() => {
    if (lastSelectedPreview) {
      const uiJSON = getUiJSON();
      if (!uiJSON) return;

      let backgroundColor = uiJSON?.backgroundColor.color;

      if (activeStep === 'Color' && colors) {
        backgroundColor = colors.values.backgroundcolor1.color;
      }

      send('NEW PREVIEW', {preview: lastSelectedPreview, backgroundColor});
    }
  }, [lastSelectedPreview, colors]);

  const dismissError = useCallback(() => {
    setIsMerchHidden(true);
  }, []);

  const renderGoodStateView = () => {
    return (
      <div
        className={`${
          state.context.error ? 'hidden' : 'grid'
        } ${itemsContainerClasses} h-3/4 justify-center gap-4 overflow-y-scroll rounded-t-lg bg-white lg:justify-items-center lg:rounded-b-lg lg:rounded-t-none lg:pt-0`}
        style={{
          // In order to make the items center aligned on mobile devices
          // we need to set the gridTemplateColumns to the number of items
          // in the merch array that are not failed after the preview is processed
          gridTemplateColumns: isMobileDevice
            ? `repeat(${merch
                .filter(i => {
                  return i.previewStatus !== PreviewStatuses.FAILED;
                })
                .length.toString()}, min-content)`
            : 'repeat(1, 1fr)',
        }}
      >
        {isMerchEmpty ? (
          <>
            <SkeletonItem className={itemsHeight} />
            <SkeletonItem className={itemsHeight} />
            <SkeletonItem className={itemsHeight} />
            <SkeletonItem className={itemsHeight} />
          </>
        ) : (
          merch.map(item => (
            <Item className={`${itemsHeight} animate-fadeIn`} key={item.id} itemData={item} />
          ))
        )}
      </div>
    );
  };

  return (
    <div
      className={`flex ${
        isMerchHidden ? 'h-0 w-full lg:h-full lg:w-0' : 'h-auto w-full lg:w-[207px]'
      } flex-col drop-shadow `}
      id="merch-ctn"
    >
      <div className="lg:pr-5 lg:pt-8">
        <TooltipNotification target={merchTitleRef} open={!isMerchEmpty}>
          <Text size="lg">View in real time what your logo will look like on our mockups!</Text>
        </TooltipNotification>
        <div
          className={`grid ${itemsContainerClasses} hidden auto-rows-min justify-items-center gap-4 rounded-t-lg bg-white lg:block`}
        >
          <Text
            as="h2"
            size="2xl"
            weight="semibold"
            className="text-cold-gray-600"
            ref={merchTitleRef}
          >
            Merch preview
          </Text>
        </div>
        {isMerchHidden ? null : (
          <button
            onClick={onTabClick}
            type="button"
            className={`absolute left-1/2 h-10 w-24 -translate-x-1/2 translate-y-[calc(-100%+1px)] content-center rounded-t-lg bg-white lg:hidden ${roundedBorders}`}
          >
            <IcChevronExpand className={`m-auto h-5 w-5 ${!isTabOpen && 'rotate-180'}`} />
            <Text as="h2" size="xs" weight="semibold" className="text-center">
              Merch preview
            </Text>
          </button>
        )}
        <div className="relative h-full flex-1">
          {state.context.error && <ErrorScreen onDismiss={dismissError} />}
          {renderGoodStateView()}
        </div>
      </div>
    </div>
  );
}
MerchContainer.defaultProps = {
  wide: false,
  isTabOpen: true,
  onTabClick: () => {},
};
MerchContainer.propTypes = {
  wide: PropTypes.bool,
  isTabOpen: PropTypes.bool,
  onTabClick: PropTypes.func,
};

export default MerchContainer;
