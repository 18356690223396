import {
  setup,
  renderLowResStageBuilder as lowResStageBuilder,
  renderLowResStagesBuilder as lowResMultiStageBuilder,
  renderLowResVariationsBuilder as lowResVariationsBuilder,
  renderSVGStagesBuilder as svgStagesBuilder,
} from 'yggdrasil';

import Router from 'next/router';
import {datadogLogs} from '@datadog/browser-logs';
import config from './config';

import {LOGO_PROCESSING_CHANGES, LOGO_PROCESSING_VARIATIONS} from '../../../analytics';

setup(config);

function withTracking(fn, {eventName, ...eventProps}) {
  // return fn if not running in browser
  if (typeof window === 'undefined') {
    return fn;
  }

  const useTracking = Router?.router?.query?.use_tracking === 'true' || false;

  // return fn unless the use_tracking query param is present and set to true
  if (!useTracking) {
    return fn;
  }

  return (...args) =>
    fn(...args).then(r => {
      datadogLogs.logger.info(eventName, {
        processingData: {
          smartTemplateId: r.source.id,
          presetId: r.source.preset,
          renderTime: r.renderTime,
          ...eventProps,
        },
      });
      return r;
    });
}

export function lowStageChangesBuilder(changes) {
  const lowResStage = lowResStageBuilder();

  const changesToApply = {};
  if (changes.contents) {
    changesToApply.text1 = {contents: changes.contents};
  }

  if (changes.graphic1) {
    changesToApply.graphic1 = {...changes.graphic1};
  }

  return withTracking(lowResStage(changesToApply), {
    eventName: LOGO_PROCESSING_CHANGES,
    changes: changesToApply,
  });
}

export function getLowStageVariations({smartTemplateId, presetId}) {
  const lowResStageVariations = lowResVariationsBuilder();
  const fn = lowResStageVariations({
    id: smartTemplateId,
    preset: presetId,
  });

  return (...args) => {
    return fn(...args).map((prom, idx) =>
      withTracking(prom, {eventName: LOGO_PROCESSING_VARIATIONS, changes: args[0][idx]})
    );
  };
}

export function lowResMerchBuilder(image, color) {
  const lowResMerch = lowResMultiStageBuilder();
  return lowResMerch({
    customgraphic1: {
      hash: null,
      layers: [
        {
          image,
        },
      ],
    },
    graphic1: {
      color,
    },
  });
}

export function lowResSvg(stageMockups) {
  const svg = svgStagesBuilder(stageMockups);

  return {
    renderChanges: (image, color) => {
      return svg.set({
        customgraphic1: {
          hash: null,
          layers: [
            {
              image,
            },
          ],
        },
        graphic1: {
          color,
        },
      });
    },
    destroy: svg.destroy,
  };
}
