import BaseButtonLocal from '../../../UI/Molecules/BaseButton';
import {IcArrowDown} from '../../../UI/Atoms/Icons';

function ShowMoreButton({...args}) {
  return (
    <BaseButtonLocal
      variant="capsule"
      rightIcon={<IcArrowDown className="h-[18px] w-[18px]" />}
      className="absolute -bottom-20 left-1/2 z-10 flex -translate-x-1/2 items-center"
      {...args}
    >
      Show More
    </BaseButtonLocal>
  );
}

export default ShowMoreButton;
