import {createMachine} from 'xstate';

/**
 * Generate the step name based on the step number
 *
 * @param {number} stepNumber - The step number
 * @returns {string} - The step name
 *
 * @example
 * stepNumber = 1 -> 'Step1'
 */
const getStepName = stepNumber => `Step${stepNumber}`;

/**
 * Generate the config of the last step based on the name of the step
 *
 * @param {string} stepName - The name of the step
 * @returns {object} - The config of the last step
 *
 * @example
 *  stepName = 'Step1' -> {type: 'final'}
 */
const createFinalStep = stepName => {
  return {
    [stepName]: {
      type: 'final',
    },
  };
};

/**
 * Generate the config of a step based on:
 *
 * @param {string} current - The name of the step
 * @param {string} next - The name of the next step
 * @param {string | null} prev - The name of the previous step
 * @param {string | null} last - The name of the last step
 * @returns {object} - The config of the step

 * @example
 * current = 'Step2'
 * next = 'Step3'
 * prev = 'Step1'
 * last = 'Step4'
 * ->
 * {
 *   Step2: {
 *     on: {
 *       NEXT: { target: 'Step1' },
 *       PREV: { target: 'Step3' },
 *       FINISH: { target: 'Step4' },
 *     },
 *   },
 * },
 */
const createStep = (current, next, prev, last, first) => {
  const stepConfig = {
    [current]: {
      on: {
        RESET: {
          target: first,
        },
        PREV: {
          target: prev,
        },
        NEXT: {
          target: next,
        },
        FINISH: {
          target: last,
        },
      },
    },
  };

  if (!prev) {
    delete stepConfig[current].on.PREV;
  }

  if (!last) {
    delete stepConfig[current].on.FINISH;
  }

  return stepConfig;
};

/**
 * Generate an array of steps names based on the number of steps
 *
 * @param {number} numberOfSteps - The number of steps
 * @returns {Array<string>} - The array of steps names
 *
 * Ex: 2 -> ['Step1', 'Step2']
 */
const generateStepNames = numberOfSteps => {
  const tempArray = [...Array(numberOfSteps)];
  return tempArray.map((_, i) => getStepName(i + 1));
};

/**
 * Create the wizard machine
 *
 * @param {string} id - The id of the machine
 * @param {number | Array<string>} steps - The number of steps or an array of steps names
 */
export default function createWizardMachine(id, steps) {
  if (!id) {
    throw new Error('id is required');
  }

  if (typeof steps === 'undefined') {
    throw new Error('steps is required');
  }

  if (typeof steps !== 'number' && !Array.isArray(steps)) {
    throw new Error('steps must be an array or a number');
  }

  const iteratorArray = typeof steps === 'number' ? generateStepNames(steps) : steps;

  return createMachine({
    id,
    predictableActionArguments: true, // This is not necessary for this machine, but it is good practice
    initial: iteratorArray[0],
    states: iteratorArray.reduce((inc, _, index) => {
      let prevStepName = iteratorArray[index - 1] || null;
      const stepName = iteratorArray[index];
      const nextStepName = iteratorArray[index + 1];
      const firstStepName = iteratorArray[0];
      const lastStepName = iteratorArray[iteratorArray.length - 1];

      if (stepName === lastStepName) {
        return {
          ...inc,
          ...createFinalStep(lastStepName),
        };
      }

      if (index === 0) {
        prevStepName = null;
      }

      return {
        ...inc,
        ...createStep(stepName, nextStepName, prevStepName, lastStepName, firstStepName),
      };
    }, {}),
  });
}
