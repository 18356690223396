import {niceAssetsCdn} from '../../../buckets-cdn';

const environment = 'production';

const devPaths = {
  base: 'https://smart-templates.us',
  cdn: niceAssetsCdn.staging,
};

const cdnPaths = {
  development: devPaths,
  staging: devPaths,
  production: {
    base: 'https://nicev2.placeit.net',
    cdn: niceAssetsCdn.production,
  },
};

// New CONFIG after changes
const newConfig = {
  environment,
  basePath: cdnPaths[environment].base,
  cdnPath: cdnPaths[environment].cdn,
  queue: {
    minConsumers: 8,
    maxConsumers: 16,
    itemsPerConsumer: 8,
  },
  asgardQueue: {
    minConsumers: 4,
    maxConsumers: 8,
    itemsPerConsumer: 4,
  },
  workers: {
    min: 8,
    max: 16,
  },
  v5Scripts: ['https://assets-staging-placeit-net.s3.amazonaws.com/assets/v5_engine_v1.js'],
};

// export default config;
export default newConfig;
