import {createMachine, assign, spawn, send} from 'xstate';

import {getDefaultIndustry} from '../../../../lib/placeit/services/industries';
import createInfiniteScrollMachine from '../../../../machines/infiniteScroll.machine';
import {getGridItems} from '../../../../lib/placeit/services/logos';
import {
  trackEvent,
  NAME_SUBMITED,
  SELECT_INDUSTRY,
  SELECT_GRAPHIC,
} from '../../../../lib/analytics';
import debounce from '../../../../lib/utils/debounce';

const DEBOUNCE_TRACK_EVENT_NAME_SUBMITED = 5000;

let fetchItemFn = null;

const getSnapshot = ref => ref?.getSnapshot();
const getProp = prop => ref => getSnapshot(ref)?.[prop];
const getContext = getProp('context');
const getStateValue = getProp('value');
const getItems = ref => getContext(ref)?.items;

const debounceTrackEventNameSubmited = debounce(event => {
  if (event.value) {
    trackEvent(NAME_SUBMITED, {companyName: event.value});
  }
}, DEBOUNCE_TRACK_EVENT_NAME_SUBMITED);

const createSelectLogoMachine = (initialIndustry, initialQuery, initialLogos) => {
  const startingPage = initialLogos?.items.length ? 2 : 1;
  fetchItemFn = getGridItems(startingPage, initialIndustry || getDefaultIndustry());
  return createMachine(
    {
      id: 'selectLogo',
      preserveActionOrder: true,
      predictableActionArguments: true,
      initial: 'idle',
      context: {
        companyName: '',
        selectedIndustry: initialIndustry || getDefaultIndustry(),
        query: initialQuery || '',
        selectedLogo: null,
        selectedGraphic: {id: 'fake'},
        items: initialLogos?.items || [],
        fetching: false,
        infiniteScrollMachineRef: null,
        sortBy: 'best',
        minimalUI: false,
        showMoreActivated: false,
        activateCallCount: 0,
      },
      states: {
        loading: {
          entry: [
            'initInfiniteScrollMachineRef',
            'resetItems',
            send('CAN_FETCH', {to: context => context.infiniteScrollMachineRef}),
            'fetchMore',
          ],
          on: {
            'xstate.update': [
              {
                target: 'idle',
                actions: ['updateContextFromChild'],
                cond: 'hasItems',
              },
              {
                actions: ['updateContextFromChild'],
              },
            ],
            SET_SELECTED_INDUSTRY: [
              {
                actions: ['setSelectedIndustry', 'clearSelectedLogo'],
                cond: 'isNotSameIndustry',
                target: 'loading',
              },
            ],
            SET_SORT_BY: [
              {
                actions: ['setSortBy', 'clearSelectedLogo'],
                cond: 'isNotSameSort',
                target: 'loading',
              },
            ],
            SET_QUERY: {
              actions: ['setQuery', 'clearSelectedLogo', 'setDefaultIndustry'],
              cond: 'isNotSameQuery',
              target: 'loading',
            },
          },
        },
        idle: {
          entry: ['initInfiniteScrollMachineRef'],

          on: {
            SET_QUERY: {
              actions: ['setQuery', 'clearSelectedLogo', 'setDefaultIndustry'],
              cond: 'isNotSameQuery',
              target: 'loading',
            },
            SET_COMPANY_NAME: {
              actions: ['setCompanyName', 'trackEventNameSubmited'],
            },
            SET_SELECTED_INDUSTRY: [
              {
                actions: ['setSelectedIndustry', 'clearSelectedLogo', 'trackEventSelectIndustry'],
                cond: 'isNotSameIndustry',
                target: 'loading',
              },
            ],
            SET_SELECTED_LOGO: {
              actions: 'setSelectedLogo',
            },
            SET_SELECTED_GRAPHIC: {
              actions: ['setSelectedGraphic', 'trackEventSelectGraphic'],
            },
            SET_SORT_BY: [
              {
                actions: ['setSortBy', 'clearSelectedLogo'],
                cond: 'isNotSameSort',
                target: 'loading',
              },
            ],
            FETCH_MORE: {
              actions: ['fetchMore', 'activateMinimalUI', 'trackEventShowMore'],
            },
            SHOW_MORE_CLICKED: {
              actions: [
                'activateShowMore',
                send('CAN_FETCH', {to: context => context.infiniteScrollMachineRef}),
              ],
            },
            'xstate.update': {
              actions: ['updateContextFromChild'],
            },
          },
        },
      },
    },
    {
      guards: {
        hasItems: context => getItems(context.infiniteScrollMachineRef)?.length > 0,
        isNotSameIndustry: (context, e) => {
          return context.selectedIndustry.id !== e.value.id;
        },
        isNotSameSort: (context, e) => {
          return context.sortBy !== e.value;
        },
        isNotSameQuery: (context, e) => {
          return context.query !== e.value;
        },
      },
      actions: {
        // Update context of this machine based on the context of the child machine
        updateContextFromChild: assign({
          items: context => {
            const itemsFromChildMachine = getItems(context.infiniteScrollMachineRef);
            return itemsFromChildMachine.map(item => {
              const itemInContext = context.items.find(i => i.id === item.id);
              if (!itemInContext) {
                return item;
              }

              return itemInContext;
            });
          },
          fetching: context => getStateValue(context.infiniteScrollMachineRef) === 'fetching',
        }),
        // Ask the child machine to fetch more items
        fetchMore: send(
          {
            type: 'FETCH_MORE',
          },
          {to: context => context.infiniteScrollMachineRef}
        ),
        // Ask the child machine to reset its items
        resetItems: send(
          {
            type: 'RESET',
          },
          {to: context => context.infiniteScrollMachineRef}
        ),
        //  Stop any existing infinite scroll machine and create a new one
        initInfiniteScrollMachineRef: assign({
          infiniteScrollMachineRef: context => {
            if (context.infiniteScrollMachineRef) {
              context.infiniteScrollMachineRef.stop();
            }

            return spawn(
              createInfiniteScrollMachine(context.items).withConfig({
                services: {
                  fetchMore: () => {
                    // TODO: There is an issue here with the pagination.
                    return fetchItemFn(context.selectedIndustry, context.sortBy, context.query);
                  },
                },
                context: {
                  canFetch: context.showMoreActivated,
                },
              }),
              {sync: true}
            );
          },
        }),

        /**
         * Save to context actions
         */
        setCompanyName: assign({
          companyName: (_, event) => event.value.trim(),
        }),
        setSelectedIndustry: assign({
          selectedIndustry: (_, event) => event.value,
        }),
        setDefaultIndustry: assign({
          selectedIndustry: () => getDefaultIndustry(),
        }),
        setSelectedLogo: assign({
          selectedLogo: (_, event) => event.value,
        }),
        clearSelectedLogo: assign({
          selectedLogo: () => null,
        }),
        setSelectedGraphic: assign({
          selectedGraphic: (_, event) => event.value,
        }),
        setSortBy: assign({
          sortBy: (_, event) => event.value,
        }),
        setQuery: assign({
          query: (_, event) => event.value,
        }),
        activateMinimalUI: assign({
          activateCallCount: context => context.activateCallCount + 1,
          minimalUI: context => context.activateCallCount > 1,
        }),
        activateShowMore: () => {
          assign({
            showMoreActivated: true,
          });
          send('CAN_FETCH', {to: context => context.infiniteScrollMachineRef});
        },
        trackEventNameSubmited: (_, event) => debounceTrackEventNameSubmited(event),
        trackEventSelectIndustry: (_, event) =>
          trackEvent(SELECT_INDUSTRY, {industry: event.value}),
        trackEventSelectGraphic: (_, event) => trackEvent(SELECT_GRAPHIC, {graphic: event.value}),
      },
    }
  );
};

export default createSelectLogoMachine;
