import {useEffect, createContext, useContext, Children} from 'react';
import PropTypes from 'prop-types';
import {Slot} from '@radix-ui/react-slot';

import useWizardMachine from '../../../../hooks/wizard/useWizardMachine';

// Context for Wizard
const WizardContext = createContext(null);

function WizardProvider({children, value}) {
  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>;
}

WizardProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    activeStep: PropTypes.string.isRequired,
    isActiveStep: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    goFinish: PropTypes.func.isRequired,
    canGoNext: PropTypes.func.isRequired,
    canGoBack: PropTypes.func.isRequired,
    canGoFinish: PropTypes.func.isRequired,
  }).isRequired,
};

export function useWizardContext() {
  const context = useContext(WizardContext);
  if (context === null) {
    throw new Error('useWizardContext must be used within a WizardProvider');
  }
  return context;
}
// Context END

// Wizard Indicator
function WizardIndicator({value, children, className, asChild, ...props}) {
  const onlyChild = Children.only(children);

  const {isActiveStep} = useWizardContext();
  const isActive = isActiveStep(value);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp data-testid="wizard-indicator" data-active={isActive} className={className} {...props}>
      {onlyChild}
    </Comp>
  );
}

WizardIndicator.defaultProps = {
  className: '',
  asChild: false,
};

WizardIndicator.propTypes = {
  // TODO: Change id prop to another name
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  asChild: PropTypes.bool,
};
// Wizard Indicator END

// Wizard Step
function WizardStep({value, children, className, asChild, onActive, ...props}) {
  const onlyChild = Children.only(children);

  const {isActiveStep} = useWizardContext();
  const isActive = isActiveStep(value);

  useEffect(() => {
    if (isActive && onActive) {
      onActive();
    }
  }, [isActive]);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp data-testid="wizard-step" hidden={isActive === false} className={className} {...props}>
      {isActive && onlyChild}
    </Comp>
  );
}

WizardStep.defaultProps = {
  className: '',
  asChild: false,
  onActive: undefined,
};

WizardStep.propTypes = {
  // TODO: Change id prop to another name
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  asChild: PropTypes.bool,
  onActive: PropTypes.func,
};
// Wizard Step END

// Wizard Base Button

function WizardBaseButton({
  children,
  onClick,
  disabled,
  className,
  asChild,
  action,
  canAction,
  ...props
}) {
  const onlyChild = Children.only(children);

  const wizardContextValue = useWizardContext();

  const actionFn = wizardContextValue[action];
  const canActionFn = wizardContextValue[canAction];

  const onClickFn = () => {
    if (canActionFn() && disabled !== true) {
      actionFn();
      onClick && onClick();
    }
  };

  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      data-testid="wizard-btn"
      type={!asChild ? 'button' : null}
      className={className}
      onClick={onClickFn}
      disabled={!canActionFn() || disabled}
      {...props}
    >
      {onlyChild}
    </Comp>
  );
}

WizardBaseButton.defaultProps = {
  onClick: undefined,
  disabled: false,
  className: '',
  asChild: false,
};

WizardBaseButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  asChild: PropTypes.bool,
  action: PropTypes.oneOf(['goBack', 'goNext', 'goFinish']).isRequired,
  canAction: PropTypes.oneOf(['canGoBack', 'canGoNext', 'canGoFinish']).isRequired,
};

// Wizard Base Button END

// Next Button
function WizardNext(props) {
  return <WizardBaseButton action="goNext" canAction="canGoNext" {...props} />;
}
// Next Button END

// Back Button
function WizardBack(props) {
  return <WizardBaseButton action="goBack" canAction="canGoBack" {...props} />;
}
// Back Button END

// Finish Button
function WizardFinish(props) {
  return <WizardBaseButton action="goFinish" canAction="canGoFinish" {...props} />;
}
// Finish Button END

// Wizard Root
function Wizard({id, steps, children}) {
  const wizardContext = useWizardMachine(id, steps);
  return <WizardProvider value={wizardContext}>{children}</WizardProvider>;
}

Wizard.propTypes = {
  id: PropTypes.string.isRequired,
  steps: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  children: PropTypes.node.isRequired,
};
// Wizard Root END

/**
 * -------------------------
 * Rename to export two names per function
 * -------------------------
 */

const Root = Wizard;
const Indicator = WizardIndicator;
const Step = WizardStep;
const Back = WizardBack;
const Next = WizardNext;
const Finish = WizardFinish;

/**
 * -------------------------
 * Exports
 * -------------------------
 */
export {
  // Original Wizard Components
  Wizard,
  WizardIndicator,
  WizardStep,
  WizardNext,
  WizardBack,
  WizardFinish,
  // Renamed Wizard Components
  Root,
  Indicator,
  Step,
  Next,
  Back,
  Finish,
};
