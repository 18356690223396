import dynamic from 'next/dynamic';
import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import * as Wizard from '../UI/Organisms/Wizard';

import useLogoMakerContext, {LogoMakerProvider} from './LogoMaker.context';

import IndicatorsContainer from './Indicators/Container';
import MerchContainer from './Merch/Container';

import SelectLogo from './Steps/SelectLogo';
import {SelectLogoProvider} from './Steps/SelectLogo/SelectLogo.context';
import useIndustries from '../../hooks/useIndustries';
import useWindowDimensions from '../../hooks/wizard/useWindowDimensions';
import useNavbarHeight from '../../hooks/useNavbarHeight';
import useCampaignContext from '../../context/CampaignContext';

const Fonts = dynamic(() => import('./Steps/Fonts'), {ssr: false});
const Colors = dynamic(() => import('./Steps/Colors'), {ssr: false});

const steps = ['Logo', 'Font', 'Color', 'Finish'];
const wizardId = 'logo-maker-wizard';

function LogoMaker({industryTag, initialLogos}) {
  const isFinalStep = false;
  const windowDimensions = useWindowDimensions();
  const navbarHeight = useNavbarHeight();
  const {headerstripHeight} = useCampaignContext();

  const {industries} = useIndustries();
  const initialIndustry = industries.find(industry => industry.fTag === industryTag);

  const {logo, font, fetchingUiJSON} = useLogoMakerContext();

  const [isMerchVisible, setIsMerchVisible] = useState(true);
  const containerHeight = windowDimensions.height
    ? windowDimensions.height - navbarHeight - headerstripHeight
    : null;

  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    const handleScroll = event => {
      if (element.offsetHeight + element.scrollTop >= element.scrollHeight && event.deltaY > 0) {
        event.preventDefault();
      }
    };

    element.addEventListener('wheel', handleScroll);

    return () => {
      element.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <div
      className="relative isolate flex h-[calc(100vh-88px)] w-full lg:h-[calc(100vh-116px)]"
      style={{
        height: containerHeight,
      }}
    >
      <Wizard.Root id={wizardId} steps={steps}>
        <div className="grid h-full w-full flex-1 grid-cols-1 grid-rows-[3.375rem_1fr_4.25rem] bg-cold-gray-100 transition-all duration-300">
          {/* Indicators */}
          <div
            className={`row-span-1 row-start-1 flex h-[3.375rem] items-center ${
              isFinalStep ? 'col-span-2' : 'col-span-1'
            } bg-cold-gray-200 text-cold-gray-700`}
          >
            <IndicatorsContainer stepsNames={steps} />
          </div>
          <div className="relative row-span-2 row-start-2 flex-1">
            <div className="h-full">
              {/* Steps */}
              <SelectLogoProvider initialLogos={initialLogos} initialIndustry={initialIndustry}>
                <SelectLogo value={steps[0]} />
              </SelectLogoProvider>
              {logo !== null && !fetchingUiJSON && <Fonts value={steps[1]} />}
              {font !== null && <Colors value={steps[2]} />}
            </div>
            {/* Merch */}
            <div
              ref={ref}
              className={`
              absolute bottom-16
              z-10 w-full transition-transform
              lg:bottom-auto
              lg:right-0 lg:top-0 lg:h-[calc(100%-4.25rem)]
              lg:w-fit  lg:min-w-max lg:translate-y-0 lg:overflow-x-visible
              lg:overflow-y-scroll
              ${isMerchVisible ? 'translate-y-0' : 'translate-y-[96%]'}
              ${logo ? 'block' : 'hidden'}`}
            >
              <MerchContainer
                wide={isFinalStep}
                onTabClick={() => setIsMerchVisible(!isMerchVisible)}
                isTabOpen={isMerchVisible}
              />
            </div>
          </div>
        </div>
      </Wizard.Root>
    </div>
  );
}

LogoMaker.defaultProps = {
  industryTag: null,
  initialLogos: null,
};

LogoMaker.propTypes = {
  industryTag: PropTypes.string,
  initialLogos: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

LogoMaker.metadata = {
  pageCategory: 'Logos',
  pageType: 'Category Page',
};

export default function LogoMakerWithProvider(props) {
  return (
    <LogoMakerProvider>
      <LogoMaker {...props} />
    </LogoMakerProvider>
  );
}
