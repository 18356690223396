/*
 * Maybe this is not the best filesystem location for this file,
 * but it can be moved later if needed.
 */

export default class StageLinkBuilder {
  constructor(logo, uiJSON, fontName, colors, userCustomization) {
    this.logoRecord = uiJSON;
    this.defaultStageLink = logo.stage_link;

    this.fontName = fontName;

    this.colors = colors?.values;
    this.text = userCustomization.companyName;
    this.graphicId = userCustomization.graphicId;
  }

  getDefaultText() {
    return this.logoRecord?.text?.find(({tag}) => tag === 'TEXT1');
  }

  getTextParamName() {
    const defaultText = this.getDefaultText();
    if (!defaultText) {
      return null;
    }
    return `textText_${defaultText.name}`;
  }

  getFontParamName() {
    const defaultText = this.getDefaultText();
    if (!defaultText) {
      return null;
    }
    return `fontText_${defaultText.name}`;
  }

  getTextColorParamName(text = this.getDefaultText()) {
    if (!text) {
      return null;
    }
    return `colorText_${text.name}`;
  }

  static getBackgroundParamName() {
    return 'colorFolder_Background';
  }

  getDefaultGraphic() {
    return this.logoRecord?.graphic?.find(({tag}) => tag === 'GRAPHIC1');
  }

  getGraphicParamName() {
    const defaultGraphic = this.getDefaultGraphic();
    if (!defaultGraphic) {
      return undefined;
    }
    return `multiFolder_${this.getDefaultGraphic().name}`;
  }

  getGraphicValue() {
    if (this.graphicId.id === 'fake') {
      return '';
    }
    if (this.graphicId.id === 'no image') {
      return null;
    }
    return this.graphicId.id;
  }

  getGraphicColorParamName(graphic = this.getDefaultGraphic()) {
    if (!graphic) {
      return undefined;
    }
    return `colorFolder_${graphic.name}`;
  }

  getGraphicColor() {
    const defaultGraphic = this.getDefaultGraphic();
    if (!defaultGraphic) {
      return undefined;
    }

    if (this.colors) {
      return this.colors?.graphic1.color;
    }

    return defaultGraphic.color;
  }

  getGraphicAccentColorParamName() {
    const defaultGraphic = this.getDefaultGraphic();
    if (!defaultGraphic) {
      return undefined;
    }
    return `colorAccentFolder_${defaultGraphic.name}`;
  }

  getGraphicAccentColor() {
    const defaultGraphic = this.getDefaultGraphic();
    if (!defaultGraphic) {
      return undefined;
    }

    // TODO: THis would be awesome
    // if (this.colors) {
    //   return this.colors?.graphic1.accentColor;
    // }

    const accentLayer = defaultGraphic.layers?.[0]?.layers?.find(({name}) => name === 'Accent');
    if (!accentLayer) {
      return undefined;
    }

    return accentLayer.color;
  }

  getDefaultGraphicHiddenStatusParamName() {
    if (!this.getDefaultGraphic()) return undefined;
    return `stateFolder_${this.getDefaultGraphic().name}`;
  }

  getDefaultGraphicHiddenStatus() {
    if (!this.getDefaultGraphic() || this.getGraphicValue() !== null) return null;
    return 'hidden';
  }

  build() {
    const params = new URLSearchParams({
      // Background color
      [StageLinkBuilder.getBackgroundParamName()]: this.colors?.backgroundcolor1.color || '',

      // TEXT1 ( the only text that gets modified in the 1st and 2nd step )
      [this.getTextParamName()]: this.text || '',
      [this.getFontParamName()]: this.fontName || '',

      // GRAPHIC1 ( the only graphic that gets modified in the 1st )
      [this.getGraphicParamName()]: this.getGraphicValue(),
      [this.getGraphicAccentColorParamName()]: this.getGraphicAccentColor(),
      [this.getDefaultGraphicHiddenStatusParamName()]: this.getDefaultGraphicHiddenStatus(),

      // MERCH preview enabled for the editor to show it
      merchPreviewEnabled: true,
      logoMakerFinished: true,
    });

    // Add the text colors query params
    this.addTextColorsQueryParams(params);

    // Add the graphic colors query params
    this.addGraphicColorsQueryParams(params);

    const paramsCopy = new URLSearchParams(params);
    let paramsString = '';

    // Remove all params with empty values
    params.forEach((value, key) => {
      if (value === '' || value === 'null' || value === 'undefined') {
        paramsCopy.delete(key);
      } else {
        paramsString += `${key}=${encodeURIComponent(value)}&`;
      }
    });

    // TODO: Update the URL to point to the LEGACY APP URL
    const selectedStageLink = `/${this.defaultStageLink}?${paramsString}`
      .replace(/\/\//g, '/')
      .replace(/\/\?/g, '?')
      .replace(/&$/, '');

    return selectedStageLink;
  }

  // This method updates the params argument, which is an instance of
  // the URLSearchParams class.
  // It iterates over the keys of the colors object and adds the corresponding
  // query params to the params argument.
  addTextColorsQueryParams(params) {
    if (!this.colors) {
      return;
    }

    Object.keys(this.colors).forEach(key => {
      if (key.startsWith('text')) {
        // Find in the texts property of the logoRecord the text with a tag
        // property that matches the key on its uppercase version.
        const text = this.logoRecord.text.find(({tag}) => tag === key.toUpperCase());

        // If the text was found, add the corresponding query param to the params
        // argument.
        if (text) {
          const paramName = this.getTextColorParamName(text);
          params.set(`${paramName}`, this.colors[key].color);
        }
      }
    });
  }

  // This method does the same as the addTextColorsQueryParams method, but
  // for the graphic colors.
  addGraphicColorsQueryParams(params) {
    if (!this.colors) {
      return;
    }

    Object.keys(this.colors).forEach(key => {
      if (key.startsWith('graphic')) {
        const graphic = this.logoRecord.graphic.find(({tag}) => tag === key.toUpperCase());

        if (graphic) {
          const paramName = this.getGraphicColorParamName(graphic);
          params.set(`${paramName}`, this.colors[key].color);
        }
      }
    });
  }
}
