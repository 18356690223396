import PropTypes from 'prop-types';

function SkeletonItem({className}) {
  return (
    <div
      className={`${className} relative animate-skeleton overflow-hidden rounded-md bg-skeleton-gradient bg-[length:200%_100%]`}
    />
  );
}
SkeletonItem.propTypes = {
  className: PropTypes.string,
};
SkeletonItem.defaultProps = {
  className: '',
};
export default SkeletonItem;
