import PropTypes from 'prop-types';

import Heading from '../../UI/Atoms/Typography/Heading';
import Text from '../../UI/Atoms/Typography/Text';
import Button from '../../UI/Molecules/BaseButton';

function Unplugged() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="97px"
      height="97px"
      viewBox="0 0 97 97"
      style={{enableBackground: 'new 0 0 97 97'}}
      xmlSpace="preserve"
    >
      <style type="text/css">
        {`.st0 { fill: #E8EDF4; }
            .st1{fill:#E8EDF4;filter:url(#Adobe_OpacityMaskFilter);}
            .st2{mask:url(#mask0_2944_10233_00000026158743171378561050000012807940852664862854_);}
            .st3{fill:#9FB2CC;}
            .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#9FB2CC;}
        `}
      </style>
      <path
        className="st0"
        d="M48.4,5.6L48.4,5.6c23.6,0,42.8,19.1,42.8,42.8l0,0C91.2,72,72,91.2,48.4,91.2l0,0C24.8,91.2,5.6,72,5.6,48.4
	l0,0C5.6,24.8,24.8,5.6,48.4,5.6z"
      />
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter"
          filterUnits="userSpaceOnUse"
          x="-23.6"
          y="16.4"
          width="146"
          height="68.8"
        >
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x="-23.6"
        y="16.4"
        width="146"
        height="68.8"
        id="mask0_2944_10233_00000026158743171378561050000012807940852664862854_"
      >
        <path
          className="st1"
          d="M54.5,6.1L54.5,6.1c23.4,3.3,39.6,25,36.3,48.4l0,0c-3.3,23.4-25,39.6-48.4,36.3h0C19,87.4,2.7,65.7,6.1,42.3
		l0,0C9.4,19,31.1,2.7,54.5,6.1z"
        />
      </mask>
      <g className="st2">
        <path
          className="st3"
          d="M122.4,20.5c-0.8,0.3-1.7,0.6-2.5,0.9c-19.8,8.7-17.8,7.8-37.6,16.4c-1.7,0.7-2.3,1.5-2,3.5
		c0.8,6.2-2.6,12.1-8.3,14.7c-1.7,0.8-3.4,1.5-5.3,2.3c-3.9-9-7.8-17.8-11.6-26.6c7.5-4.8,16.1-5.9,23.4,3.1
		c21.3-9.3,20.9-9.1,42.2-18.5C121.2,17.8,121.8,19.1,122.4,20.5z"
        />
        <path
          className="st3"
          d="M50.6,56.1c-2.7,1-5.2,2.3-8,3.5c-1.3-2.9-2.4-5.6-3.7-8.4c0.9-0.4,1.8-0.8,2.6-1.1c2.4-1,4.8-2,7-3.2
		c0.7-0.3,0.9-1.5,1.3-2.2c-0.9-0.3-1.8-0.7-2.7-0.7c-0.6,0-1.2,0.5-1.9,0.8c-2.5,1.1-5.1,2.2-7.8,3.4c-0.9-2.1-1.8-4-2.7-6.1
		c-2.3,1-4.6,1.8-6.5,2.9c-5.3,3.1-7.9,7.8-7.3,14c0.2,2.1-0.4,2.9-2.3,3.8C4.6,68.8-9.5,75-23.6,81.2l1.7,4
		C-8,79.1,5.8,73,19.6,66.9c0.8-0.3,1.5-0.8,2.3-0.9c0.5-0.1,1.4,0,1.7,0.4c6.5,8,15,7,22.7,2.1c-0.8-1.9-1.7-3.9-2.6-5.9
		c2.9-1.2,5.4-2.3,7.9-3.5c1.3-0.7,3.9-0.8,2.9-3.1C53.7,53.8,51.9,55.6,50.6,56.1z"
        />
      </g>
      <path
        className="st4"
        d="M45.2,8.1c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5l0,13c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5
	L45.2,8.1z M26.9,11.3c-0.7-1.2-0.3-2.7,0.9-3.4c1.2-0.7,2.7-0.3,3.4,0.9l8.5,14.7c0.7,1.2,0.3,2.7-0.9,3.4
	c-1.2,0.7-2.7,0.3-3.4-0.9L26.9,11.3z M16,25.2c-0.7,1.2-0.2,2.8,1,3.5l12.1,6.2c1.2,0.6,2.6,0.2,3.3-1c0.7-1.2,0.2-2.8-1-3.5
	l-12.1-6.2C18.1,23.6,16.6,24,16,25.2z"
      />
    </svg>
  );
}

export default function ErrorScreen({onDismiss}) {
  return (
    <div className="z-10 flex h-min w-full flex-1 flex-col items-center space-y-2 rounded-lg bg-white px-4 pb-6 pt-2 lg:h-[40rem] lg:justify-center lg:space-y-5 lg:rounded-t-none">
      <div className="hidden lg:block">
        <Unplugged />
      </div>
      <Text as="h2" size="md" weight="semibold" className="block text-center lg:hidden">
        Oops!
      </Text>
      <Heading as="h2" size="md" weight="semibold" className="hidden text-center lg:block">
        Oops!
      </Heading>
      <div className="block lg:hidden">
        <Text size="xs" className="text-center">
          We tried to display your logo on some cool mock-ups, but something went wrong.
        </Text>
        <Text size="xs" className="text-center">
          Dismiss to continue.
        </Text>
      </div>
      <div className="hidden space-y-4 lg:block">
        <Text size="md" className="text-center">
          We tried to display your logo on some cool mock-ups, but something went wrong.
        </Text>
        <Text size="md" className="text-center">
          Dismiss to continue.
        </Text>
      </div>
      <div className="pt-2">
        <Button variant="tertiary" size="sm" onClick={onDismiss}>
          Dismiss
        </Button>
      </div>
    </div>
  );
}

ErrorScreen.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};
