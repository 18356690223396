import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import {useEffect, useMemo, useRef, useState, useCallback} from 'react';

import useRequestOnScroll from '../../../../hooks/useRequestOnScroll';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';

import useWindowDimensions from '../../../../hooks/wizard/useWindowDimensions';
import {breakpoints} from '../../../../lib/constants';

import useSelectLogoContext from './SelectLogo.context';
import useLogoMakerContext from '../../LogoMaker.context';

// import debounce from '../../../../lib/utils/debounce';

import Grid from '../Common/Grid';
import GridItem from '../Common/GridItem';

import {lowStageChangesBuilder} from '../../../../lib/placeit/services/yggdrasil';
import {DEFAULT_CUSTOMIZATION} from '../../../../lib/placeit/services/industries';

import useBucketsCdn from '../../../../hooks/useBucketsCdn';

const LoadingSpinner = dynamic(() => import('../Common/LoadingSpinner'));

// TODO: Move this fn to its own file.
const processItem = (item, ch) => {
  if (!item || !item.smart_template_id) {
    return;
  }

  const changes = {};

  if (ch.companyName) {
    changes.contents = ch.companyName;
  }

  if (ch.graphicId.id !== 'fake' && ch.graphicId.id !== 'no image') {
    changes.graphic1 = {
      layers: [
        {
          ...ch.graphicId,
          visible: true,
        },
      ],
    };
  }

  if (ch.graphicId.id === 'no image') {
    changes.graphic1 = {
      layers: [],
    };
  }

  const processLogoFn = lowStageChangesBuilder(changes);

  return processLogoFn({
    id: item.smart_template_id,
    preset: item.smart_template_preset_id,
  }).then(res => {
    return {
      id: item.id,
      previewImage: res.record.previewImage,
    };
  });
};

// TODO: Move this cmp to its own file.
// TODO: Try to integrate with the DefferedPreviewGridItem cmp.

function GridItemWithIntersectionObserver({changes, item, hidden, ...props}) {
  const [_item, setItem] = useState(item);

  const [processedChanges, setProcessedChanges] = useState(JSON.stringify(DEFAULT_CUSTOMIZATION));
  const [processing, setProcessing] = useState(false);
  const [onViewport, setOnViewport] = useState(hidden);

  // Processing promise ref.
  const processingPromiseRef = useRef(null);

  const {selectedLogo} = useSelectLogoContext();
  const {getStageLink, setSelectedPreview} = useLogoMakerContext();

  const internalChanges = useMemo(() => changes, [changes]);

  // const setOnViewportWithDebounce = useCallback(debounce(setOnViewport, 1000), []);
  const setOnViewportWithDebounce = setOnViewport;

  // Make the item aware of being in the viewport.
  const targetRef = useRef(null);
  useIntersectionObserver(
    targetRef,
    {
      // threshold: 1.0, // Need to be completely inside the viewport
      threshold: 0,
    },
    intersecting => {
      if (hidden) {
        return;
      }
      setOnViewportWithDebounce(intersecting);
    }
  );

  // On unmount, mark the item as not on viewport anymore.
  useEffect(() => {
    return () => {
      setOnViewport(false);
      if (processingPromiseRef.current && processingPromiseRef.current.isPending()) {
        processingPromiseRef.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (onViewport && !processing && processedChanges !== JSON.stringify(internalChanges)) {
      setProcessing(true);

      const processingPromise = processItem(_item, internalChanges);
      processingPromise
        .then(res => {
          if (!onViewport) {
            return;
          }
          setProcessedChanges(JSON.stringify(internalChanges));

          setItem({
            ..._item,
            stage_link: selectedLogo?.id === _item.id ? getStageLink() : _item.stage_link,
            large_thumb: res.previewImage.value,
          });
          setProcessing(false);

          if (selectedLogo?.id === _item.id) {
            setSelectedPreview(res.previewImage.value);
          }
        })
        .catch(err => {
          if (err.name === 'CancellationError') {
            return;
          }

          setItem({
            ..._item,
            stage_link: selectedLogo?.id === _item.id ? getStageLink() : _item.stage_link,
          });
          setProcessing(false);
        });

      // Save the promise ref to be able to cancel it on unmount.
      processingPromiseRef.current = processingPromise;
    }
  }, [internalChanges, onViewport, processing, processedChanges, _item]);

  useEffect(() => {
    setItem({...item, large_thumb: _item.large_thumb});
  }, [item]);

  const className = hidden ? 'hidden' : '';

  return (
    <span className={className}>
      <GridItem {...props} item={_item} processing={processing} ref={targetRef} />
    </span>
  );
}

GridItemWithIntersectionObserver.defaultProps = {
  changes: null,
  hidden: false,
};

GridItemWithIntersectionObserver.propTypes = {
  changes: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    graphicId: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    large_thumb: PropTypes.string.isRequired,
    smart_template_id: PropTypes.string.isRequired,
    smart_template_preset_id: PropTypes.string,
    stage_link: PropTypes.string.isRequired,
  }).isRequired,
  hidden: PropTypes.bool,
};

export default function SelectLogoGrid() {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < breakpoints.md;
  const isBrowser = typeof window !== 'undefined';

  const [wasShowMoreClicked, setShowMoreClicked] = useState(isMobile && isBrowser);
  const {items, selectedLogo, setSelectedLogo, fetchMore, fetching, minimalUI, showMoreClicked} =
    useSelectLogoContext();

  const {getStageLink, userCustomization, isMerchHidden} = useLogoMakerContext();

  const {productionStageThumbUrl} = useBucketsCdn();

  const infiniteScrollRef = useRequestOnScroll(() => {
    showMoreClicked();
    fetchMore();
  });

  const enabledShowMore = isMobile ? false : !fetching && !minimalUI;
  const activateInfiniteScroll = isMobile ? true : !fetching && minimalUI;

  const itemsToRender = items.map(item => {
    item.large_thumb = productionStageThumbUrl(item.large_thumb_path);
    if (item.id === selectedLogo?.id) {
      return {...item, stage_link: getStageLink() || selectedLogo.stage_link};
    }
    return item;
  });

  const shouldBeHidden = useCallback(
    index => {
      if (minimalUI) {
        return false;
      }

      if (wasShowMoreClicked) {
        return false;
      }

      return index > 4;
    },
    [wasShowMoreClicked, minimalUI, windowDimensions.width, selectedLogo, isMerchHidden]
  );

  const gridRef = useRef(null);

  return (
    <>
      <Grid
        header="Select a logo template"
        className="mt-6"
        onRequestMore={() => {
          setShowMoreClicked(true);
          showMoreClicked();
          fetchMore();
        }}
        canSort
        onSortChange={console.log}
        enabledShowMore={enabledShowMore}
        ref={gridRef}
      >
        {itemsToRender.map((item, i) => (
          <GridItemWithIntersectionObserver
            changes={{...userCustomization, industryId: null}}
            key={item.id}
            item={{...item, grid_position: ++i}}
            onSelect={setSelectedLogo}
            isSelect={item.id === selectedLogo?.id}
            processing={item.isProcessing}
            hidden={shouldBeHidden(i)}
            lazyRoot={gridRef}
          />
        ))}
      </Grid>
      <div className="relative flex h-32 w-full items-center justify-center py-4">
        <div
          ref={infiniteScrollRef}
          className={`h-full w-full ${
            activateInfiniteScroll ? 'block' : 'hidden'
          } absolute inset-0 -top-72 -z-10`}
        />

        {fetching && <LoadingSpinner />}
      </div>
    </>
  );
}
