import {forwardRef} from 'react';
import PropTypes from 'prop-types';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import Text from '../../Atoms/Typography/Text';

const Trigger = forwardRef(({text, icon, className, ...radixProps}, ref) => {
  const triggerHorizontalPadding = icon ? 'pr-2 pl-3' : 'px-3';
  return (
    <DropdownMenu.Trigger
      ref={ref}
      className={`flex items-center justify-between rounded-md border-1 border-cold-gray-400 bg-white py-2 hover:border-cold-gray-600 focus:border-current ${triggerHorizontalPadding}  text-cold-gray-800 outline-none ${className}`}
      {...radixProps}
    >
      <Text size="lg" data-testid="text-in-trigger" className="md:text-sm">
        {text}
      </Text>
      {icon}
    </DropdownMenu.Trigger>
  );
});

Trigger.displayName = 'Trigger';

Trigger.defaultProps = {
  icon: null,
  className: '',
};

Trigger.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default Trigger;
