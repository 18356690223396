export default class Industry {
  static parse(industry) {
    if (typeof industry === 'string') {
      return new Industry(JSON.parse(industry));
    }

    return new Industry(industry);
  }

  constructor(industry) {
    this.industryInfo = {
      id: industry.id,
      url: `/logo-maker/${industry.name}`,
      name: industry.name,
      description: industry.description,
      label: industry.label,
      parent_id: industry.parent_id,
      is_container: industry.is_container,
      f_tag: industry.f_tag,
      seoInfo: {
        title: industry.meta_title,
        description: industry.meta_description,
      },
    };
  }

  toJSON() {
    return {
      id: this.id,
      url: this.url,
      name: this.name,
      description: this.description,
      label: this.label,
      parent_id: this.parent_id,
      isContainer: this.isContainer,
      fTag: this.fTag,
      isDefault: this.isDefault,
      seoInfo: this.seoInfo,
    };
  }

  get id() {
    return this.industryInfo.id;
  }

  get url() {
    return this.industryInfo.url;
  }

  get name() {
    return this.industryInfo.name;
  }

  get description() {
    return this.industryInfo.description;
  }

  get label() {
    return this.industryInfo.label;
  }

  get parent_id() {
    return this.industryInfo.parent_id;
  }

  get isContainer() {
    return this.industryInfo.is_container;
  }

  get fTag() {
    const {f_tag: fTag} = this.industryInfo;
    return fTag === 'all' ? '' : fTag;
  }

  get isDefault() {
    return this.fTag === '';
  }

  get seoInfo() {
    return this.industryInfo?.seoInfo;
  }
}
