import {niceAssetsCdn} from '../../../buckets-cdn';

const environment = 'production';

const devPaths = {
  cdn: niceAssetsCdn.staging,
};

const cdnPaths = {
  development: devPaths,
  staging: devPaths,
  production: {
    cdn: niceAssetsCdn.production,
  },
};

// New CONFIG after changes
const newConfig = {
  environment,
  cdnPath: cdnPaths[environment].cdn,
  queue: {
    minConsumers: 1,
    maxConsumers: 3,
    itemsPerConsumer: 1,
  },
};

// export default config;
export default newConfig;
