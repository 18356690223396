import PropTypes from 'prop-types';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

function Root({children, ...radixProps}) {
  return <DropdownMenu.Root {...radixProps}>{children}</DropdownMenu.Root>;
}

Root.defaultProps = {
  children: null,
};

Root.propTypes = {
  children: PropTypes.node,
};

export default Root;
