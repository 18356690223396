import PropTypes from 'prop-types';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

import SeoTag from '../../components/SEO/SeoTag';

import PageLayout from '../../plasmic_components/PageLayoutComponent';
import LogoMakerCmp from '../../components/LogoMaker/LogoMaker';
import {getGridItems} from '../../lib/placeit/services/logos';

import {
  getIndustries,
  getIndustrySeoValues,
  setInitialIndustries,
  getIndustryFtagByIdentifier,
  getIndustryUrlByIdentifier,
} from '../../lib/placeit/services/industries';
import Industry from '../../lib/placeit/models/industry';

function LogoMakerIndustry({industries, industry, initialLogos}) {
  const router = useRouter();
  setInitialIndustries(industries || []);

  useEffect(() => {
    if (window.pageMetadata) {
      Object.assign(window.pageMetadata, {pageCategory: 'Logos'});
    }
  });

  let industryParam = '';

  if (router?.isReady) {
    industryParam = router.query.industry;

    if (Array.isArray(industryParam) && industryParam[0]) {
      [industryParam] = industryParam;
    } else {
      industryParam = '';
    }
  }

  return (
    <>
      <SeoTag config={getIndustrySeoValues(industry)} />
      <PageLayout>
        <LogoMakerCmp initialLogos={initialLogos} industryTag={industry} />
      </PageLayout>
    </>
  );
}

LogoMakerIndustry.defaultProps = {
  industries: [],
  industry: null,
  initialLogos: PropTypes.shape({}),
};

LogoMakerIndustry.propTypes = {
  industries: PropTypes.arrayOf(PropTypes.shape(Industry)),
  industry: PropTypes.shape(Industry),
  initialLogos: PropTypes.shape({}),
};

export async function getServerSideProps(context) {
  const industries = await getIndustries(null, true);
  const {industryId} = context.query;

  // Redirect if it's an old logo maker url
  if (industryId && !/logo-maker\/\w+/.test(context.resolvedUrl)) {
    const path = getIndustryUrlByIdentifier(industryId);
    return {
      redirect: {
        destination: path,
        permanent: false,
      },
    };
  }

  const industryQuery = context.query?.industry?.length && context.query.industry[0];
  const industry = getIndustryFtagByIdentifier(industryQuery);
  const logos = await getGridItems(1, industry)({fTag: industry}, 'best');

  return {
    props: {
      industries: JSON.parse(JSON.stringify(industries)),
      industry: industry || null,
      initialLogos: logos,
    },
  };
}

export default LogoMakerIndustry;

export const getSitemapEntries = () => {
  return [];
};
