import PropTypes from 'prop-types';
import {forwardRef} from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

let Element = {};
if (typeof window !== 'undefined') {
  Element = window.Element;
}

function Wrapper({portal, container, children, forceMount, ...props}) {
  if (typeof window !== 'undefined') {
    container ||= document.body;
  }
  return (
    <DropdownMenu.Portal container={container} forceMount={forceMount} {...props}>
      {children}
    </DropdownMenu.Portal>
  );
}
Wrapper.defaultProps = {
  portal: false,
  container: typeof window !== 'undefined' ? document.body : null,
  forceMount: false,
};
Wrapper.propTypes = {
  portal: PropTypes.bool,
  container: PropTypes.instanceOf(Element),
  children: PropTypes.node.isRequired,
  forceMount: PropTypes.bool,
};

const Content = forwardRef(({children, portal, container, forceMount, ...radixProps}, ref) => {
  const content = (
    <DropdownMenu.Content
      forceMount={forceMount}
      ref={ref}
      align="start"
      className="z-10 mt-[2px] rounded-md bg-white shadow-xs"
      {...radixProps}
    >
      {children}
    </DropdownMenu.Content>
  );
  return portal ? (
    <Wrapper portal={portal} container={container} forceMount={forceMount}>
      {content}
    </Wrapper>
  ) : (
    content
  );
});

Content.displayName = 'Content';

Content.defaultProps = {
  portal: false,
  container: null,
  forceMount: false,
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  portal: PropTypes.bool,
  container: PropTypes.instanceOf(Element),
  forceMount: PropTypes.bool,
};

export default Content;
