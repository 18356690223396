import Industry from '../models/industry';

const INDUSTRIES_ENDPOINT = '/api/v2/industries';

export const DEFAULT_INDUSTRY = Industry.parse({
  id: 186,
  name: 'All Logos',
  description:
    "Placeit's logo maker lets you quickly and easily create stunning logos in seconds, choose from thousands of incredible logo templates and easily edit them. You don't need to hire an expensive designer to make the perfect logo for your business or team.",
  label: 'Logos (All)',
  parent_id: null,
  isContainer: false,
  f_tag: '',
  isDefault: true,
  seoInfo: {
    title: 'Make a Logo | Online Logo Maker | Placeit ',
    description:
      "Placeit's logo maker lets you create stunning logos in seconds, choose one of our thousands of logo templates and edit them. Make the perfect logo for your project today!",
  },
});

export const DEFAULT_CUSTOMIZATION = {
  companyName: '',
  graphicId: {
    id: 'fake',
  },
  industryId: null,
};

let industries = [DEFAULT_INDUSTRY];

/**
 * @throws Will throw an error if attempting to set to an empty value
 */
export function setInitialIndustries(initialIndustries) {
  if (initialIndustries === null || initialIndustries === undefined || initialIndustries === []) {
    throw new Error("Can't set the industries to an empty value");
  } else {
    industries = initialIndustries;
  }
}

export function getInitialIndustries() {
  return industries;
}

export function getDefaultIndustry() {
  return industries.find(industry => industry.isDefault);
}

export function getIndustrySeoValues(industry = null) {
  const baseUrl = 'https://placeit.net';
  if (!industry) {
    return {...getDefaultIndustry()?.seoInfo, canonical: `${baseUrl}/logo-maker`};
  }

  const result = industries.find(ind => ind.fTag === industry);
  return {
    ...result?.seoInfo,
    canonical: `${baseUrl}${getIndustryUrlByIdentifier(industry)}`,
  };
}

export async function getIndustries(params = '', refetch = false) {
  if (industries && industries.length && !refetch) {
    return industries;
  }

  // TODO: Uncomment this line (and remove the one below) before goind to prod
  // const baseUrl = typeof window === 'undefined' ? process.env.NEXT_PUBLIC_PLACEIT_LEGACY_APP : '';
  const baseUrl = typeof window === 'undefined' ? 'https://placeit.net' : '';
  const url = `${baseUrl}${INDUSTRIES_ENDPOINT}?${params || ''}`;

  industries = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Basic YWRtaW46c3VnYXJpc3RoZW5ld2ZhdA==',
    },
  })
    .then(response => response.json())
    .then(data => data.industries.map(industry => Industry.parse(industry)));

  return industries;
}

export function getIndustryFtagByIdentifier(identifier) {
  return industries.find(industry => {
    const id = industry.name.toLowerCase().split(' ').join('-');
    return id === identifier;
  })?.fTag;
}

export function getIndustryUrlByIdentifier(identifier) {
  const industryUrl = industries.find(
    industry =>
      industry.fTag.toLowerCase() === identifier.toLowerCase() ||
      industry.name.toLowerCase() === identifier.toLowerCase()
  )?.url;
  if (industryUrl) {
    return industryUrl?.toLowerCase().split(' ').join('-');
  }
  return '/logo-maker';
}
