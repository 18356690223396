import {createMachine, assign} from 'xstate';

// TODO: Add missing tests

/**
 * Create an infinite scroll machine
 *
 * @param {Array<T>} items - The initial items for the context
 */
export default function createInfiniteScrollMachine(items = [], allowDone = true) {
  return createMachine(
    {
      id: 'infiniteScroll',
      preserveActionOrder: true,
      predictableActionArguments: true,
      initial: 'idle',
      context: {
        items,
        isThereMore: true,
        error: '',
        canFetch: false,
      },
      states: {
        fetching: {
          invoke: {
            src: 'fetchMore',
            onDone: [
              {
                target: 'idle',
                actions: ['assignFetchSuccessResultToContext'],
                cond: 'hasMore',
              },
              {
                target: 'noMoreItems',
                actions: ['assignFetchSuccessResultToContext'],
              },
            ],
            onError: {
              target: 'idle',
              actions: ['assignFetchErrorToContext'],
            },
          },
        },
        idle: {
          on: {
            FETCH_MORE: {
              target: 'fetching',
              actions: ['resetError'],
              cond: 'canProceedWithFetch',
            },
            CAN_FETCH: {
              actions: ['allowFetching'],
            },
            RESET: {
              target: 'idle',
              actions: ['resetAll'],
            },
          },
        },
        noMoreItems: {
          type: allowDone ? 'final' : 'atomic',
          on: {
            RESET: {
              target: 'idle',
              actions: ['resetAll'],
            },
          },
        },
      },
    },
    {
      actions: {
        assignFetchSuccessResultToContext: assign({
          items: (ctx, event) => {
            if (!event.type.match(/done.invoke.infiniteScroll.fetching/)) {
              return ctx.items;
            }
            return [...ctx.items, ...event.data.items];
          },
          isThereMore: (ctx, event) => {
            if (!event.type.match(/done.invoke.infiniteScroll.fetching/)) {
              return ctx.isThereMore;
            }
            return event.data.isThereMore;
          },
        }),
        assignFetchErrorToContext: assign({
          error: (ctx, event) => {
            if (!event.type.match(/error.platform.infiniteScroll.fetching/)) {
              return ctx.error;
            }
            return event.data.message;
          },
        }),
        resetError: assign({
          error: () => '',
        }),
        resetAll: assign({
          items: () => [],
          isThereMore: () => true,
          error: () => '',
        }),
        allowFetching: assign({
          canFetch: true,
        }),
      },
      guards: {
        hasMore: (ctx, event) => {
          if (event.data) {
            return event.data.isThereMore;
          }
          return event.data.isThereMore || ctx.isThereMore;
        },
        canProceedWithFetch: context => {
          return context.canFetch;
        },
      },
      services: {
        fetchMore: async () => {
          throw new Error('Not implemented');
        },
      },
    }
  );
}
