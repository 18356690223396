import PropTypes from 'prop-types';
import {memo, forwardRef, useMemo} from 'react';
import useLogoMakerContext from '../../LogoMaker.context';

import ShowMoreButton from './ShowMoreButton';
import TextUIKit from '../../../UI/Atoms/Typography/Text';
import SortBy from '../SelectLogo/SortBy';
import {trackEvent, SHOW_MORE} from '../../../../lib/analytics';

// TODO: Add test, add documentation, add story

const Text = memo(TextUIKit);

// TODO: Improve the internal structure for a better ref forwarding
const Grid = forwardRef(
  ({children, header, className, canSort, onRequestMore, enabledShowMore}, ref) => {
    const {activeStep, logo, isMerchHidden} = useLogoMakerContext();

    const gridCols = useMemo(() => {
      const cols = 'grid-cols-2 gap-2 lg:gap-6';
      return `${cols} lg:grid-cols-4`;
    }, [logo, isMerchHidden]);

    const renderShowMoreButton = () => {
      if (!enabledShowMore) return null;

      return (
        <ShowMoreButton
          onClick={() => {
            trackEvent(SHOW_MORE, {asset: activeStep});
            onRequestMore();
          }}
        />
      );
    };

    return (
      <div className={`flex flex-col gap-4 ${className} w-full max-w-screen-2xl md:mx-auto`}>
        {/* Grid header */}
        <div className="flex items-baseline justify-between">
          <Text size="lg" weight="semibold" as="h2" className="col-span-2 md:text-2xl">
            {header}
          </Text>
          {canSort && <SortBy />}
        </div>
        {/* Grid items */}
        <div ref={ref} className={`relative grid auto-rows-min ${gridCols} transition-all`}>
          {children}
          {renderShowMoreButton()}
        </div>
      </div>
    );
  }
);

Grid.displayName = 'Grid';

Grid.defaultProps = {
  children: null,
  className: '',
  canSort: false,
  onRequestMore: () => {},
  enabledShowMore: false,
};

Grid.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string.isRequired,
  className: PropTypes.string,
  onRequestMore: PropTypes.func,
  canSort: PropTypes.bool,
  enabledShowMore: PropTypes.bool,
};

export default Grid;
