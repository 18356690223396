import {createMachine, assign} from 'xstate';
import {lowResSvg} from '../../../lib/placeit/services/yggdrasil';

export const PreviewStatuses = {
  IDLE: 'idle',
  PROCESSING: 'processing',
  DONE: 'done',
  FAILED: 'failed',
};

const createMerchMachine = () =>
  createMachine(
    {
      id: 'merch_processing',
      initial: 'Idle',
      states: {
        Idle: {},
        'Fetching Items': {
          entry: 'clearItems',
          invoke: {
            src: 'fetchItems',
            onDone: [
              {
                target: 'Pre',
                actions: ['clearError', 'saveItems'],
              },
            ],
            onError: [
              {
                target: 'Idle',
                actions: 'saveError',
              },
            ],
          },
        },
        Pre: {
          entry: 'markItemsAsProcessing',
          after: {
            1000: 'Items Ready',
          },
        },
        'Items Ready': {
          initial: 'Processing Preview',
          entry: 'initSVGEngine',
          exit: 'destroySVGEngine',
          states: {
            'Processing Preview': {
              exit: 'markItemsAsNotProcessing',
              invoke: {
                src: 'processPreviews',
                onDone: [
                  {
                    target: 'Idle',
                    actions: 'updatePreviewOnItems',
                  },
                ],
                onError: [
                  {
                    target: 'Idle',
                    actions: ['saveError', 'showError'],
                  },
                ],
              },
              on: {
                PREVIEW_READY: {
                  actions: 'updateItemPreview',
                },
              },
            },
            Idle: {},
            Cancelling: {
              after: {
                1: {target: 'Processing Preview'},
              },
            },
          },
          on: {
            'NEW PREVIEW': {
              target: '.Cancelling',
              actions: ['savePreview', 'saveBackgroundColor'],
            },
            RECREATE: {
              target: 'Recreating',
            },
          },
        },
        Recreating: {
          after: {
            100: 'Fetching Items',
          },
        },
      },
      on: {
        'NEW LOGO': {
          target: '.Fetching Items',
          actions: ['saveLogo', 'savePreview', 'saveBackgroundColor'],
        },
        'CLEAR LOGO': {
          target: '.Idle',
          actions: 'clearLogo',
        },
      },
      context: {
        svgEngine: null,
        merch: [],
        logo: null,
        preview: null,
        backgroundColor: null,
        error: null,
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        saveLogo: assign({
          logo: (_, event) => event.logo,
        }),
        clearLogo: assign({
          logo: null,
        }),
        savePreview: assign({
          preview: (_, event) => {
            return event.preview;
          },
        }),
        saveBackgroundColor: assign({
          backgroundColor: (_, event) => {
            return event.backgroundColor;
          },
        }),
        saveItems: assign({
          merch: (_, event) => event.data.items,
        }),
        updatePreviewOnItems: assign({
          merch: context => context.merch,
        }),
        updateItemPreview: assign({
          merch: (context, event) => {
            return context.merch.map(item => {
              if (item.smart_template_id === event.data.record.id) {
                return {
                  ...item,
                  image_url: event.data.record.previewImage.value,
                  previewStatus: PreviewStatuses.DONE,
                };
              }
              return item;
            });
          },
        }),
        clearItems: assign({
          merch: () => [],
        }),
        saveError: assign({
          error: (_, event) => event.data,
        }),
        clearError: assign({
          error: null,
        }),
        markItemsAsProcessing: assign({
          merch: context => {
            return context.merch.map(item => ({
              ...item,
              previewStatus: PreviewStatuses.PROCESSING,
            }));
          },
        }),
        markItemsAsNotProcessing: assign({
          merch: (context, event) => {
            if (!event?.data) {
              return context.merch.map(item => ({
                ...item,
                previewStatus: PreviewStatuses.DONE,
              }));
            }

            const failingItemsIds = event.data
              .filter(promiseResult => promiseResult.status === 'rejected')
              .map(promiseResult => promiseResult.reason.source.id);

            return context.merch.map(item => ({
              ...item,
              previewStatus: failingItemsIds.includes(item.smart_template_id)
                ? PreviewStatuses.FAILED
                : PreviewStatuses.DONE,
            }));
          },
          error: (_ctx, event) => {
            if (!event?.data) {
              return null;
            }
            return event.data.every(promiseResult => promiseResult.status === 'rejected')
              ? 'All previews failed'
              : null;
          },
        }),
        initSVGEngine: assign({
          svgEngine: context => {
            const {merch} = context;
            const targets = document.querySelectorAll('#merch-ctn [data-v5-target]');

            const forProcessing = merch.map((item, idx) => ({
              id: item.smart_template_id,
              preset: item.smart_template_preset_id,
              target: targets[idx],
              width: targets[idx].clientWidth,
              height: targets[idx].clientHeight,
            }));

            return lowResSvg(forProcessing);
          },
        }),

        destroySVGEngine: assign({
          svgEngine: context => {
            context.svgEngine.destroy().map(dstr => dstr());
            return null;
          },
        }),
      },
      services: {
        fetchItems: async () => {
          throw new Error('Need to implement `fetchItems`');
        },
        processPreviews: async () => {
          throw new Error('Need to implement `processPreviews`');
        },
      },
    }
  );

export default createMerchMachine;
