/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import {useCallback, memo, forwardRef} from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Text from '../../../UI/Atoms/Typography/Text';
import useLogoMakerContext from '../../LogoMaker.context';
import StageRecord from '../../../types/StageRecord';

const EditBtn = dynamic(() => import('./EditBtn'), {ssr: false});
const ProcessingOverlay = dynamic(() => import('./ProcessingOverlay'), {ssr: false});

const GridItem = forwardRef(({item, isSelect, onSelect, lazyRoot, processing}, ref) => {
  const {getEditorLink} = useLogoMakerContext();
  const selectedClasses = isSelect ? 'ring-4 ring-primary-400' : '';

  const onClick = useCallback(
    e => {
      if (processing) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      !isSelect && onSelect(item);
    },
    [item, onSelect, processing]
  );

  return (
    <div>
      <div
        ref={ref}
        className={`group relative h-full w-full rounded-md bg-cold-gray-200 ${selectedClasses} aspect-square overflow-hidden shadow-grid-item outline-none transition-shadow duration-200 hover:ring-4 hover:ring-primary-200 focus:ring-4 focus:ring-primary-200 ${
          processing ? 'pointer-events-none' : ''
        }`}
        onClick={onClick}
        role="button"
        tabIndex={0}
      >
        <Image
          layout="responsive"
          src={item.large_thumb}
          tabIndex={-1}
          alt={item.seo_title}
          className="h-full w-full rounded-md object-cover"
          lazyRoot={lazyRoot}
          height={300}
          width={300}
        />
        {processing && <ProcessingOverlay />}
        {isSelect && (
          <EditBtn
            href={getEditorLink()}
            className="md:hidden md:group-focus-within:block md:group-hover:block md:group-focus:block"
          />
        )}
      </div>
      {item.seo_title && (
        <Text size="lg" as="h2" className="truncate py-1 text-gray-600">
          {item.seo_title}
        </Text>
      )}
    </div>
  );
});

GridItem.displayName = 'GridItem';

GridItem.defaultProps = {
  isSelect: false,
  processing: false,
  lazyRoot: null,
};

const Element = typeof window === 'undefined' ? {} : Element;

GridItem.propTypes = {
  isSelect: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  item: StageRecord.isRequired,
  processing: PropTypes.bool,
  lazyRoot: PropTypes.shape({current: PropTypes.shape(Element)}),
};

export default memo(GridItem);
