import PropTypes from 'prop-types';

import {CHOSE_LOGO, trackEvent} from '../../../../lib/analytics';

import BaseButton from '../../../UI/Molecules/BaseButton';

import useLogoMakerContext from '../../LogoMaker.context';

function FinishButton({children, disabled, logoLink, variant, ...args}) {
  const {activeStep} = useLogoMakerContext();

  const handleClick = () => {
    trackEvent(CHOSE_LOGO, {step: activeStep});
  };

  return (
    <a
      href={logoLink || ''}
      target="_blank"
      className={`grow lg:grow-0 ${disabled ? 'pointer-events-none' : ''}`}
      onClick={handleClick}
      rel="noreferrer"
    >
      <BaseButton
        variant={variant}
        disabled={disabled}
        className="group w-full flex-1 px-1 text-sm disabled:cursor-not-allowed md:min-w-[155px] md:flex-initial lg:px-5 lg:text-base"
        {...args}
      >
        {children}
      </BaseButton>
    </a>
  );
}

FinishButton.defaultProps = {
  disabled: false,
  children: 'This logo is perfect',
  logoLink: '',
  variant: 'tertiary',
};

FinishButton.propTypes = {
  disabled: PropTypes.bool,
  children: BaseButton.propTypes.children,
  logoLink: PropTypes.string,
  variant: BaseButton.propTypes.variant,
};

export default FinishButton;
