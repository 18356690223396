import {useMachine} from '@xstate/react';
import {useMemo} from 'react';

import createWizardMachine from '../../machines/wizard/wizard.machine';

/**
 * @param {WizardState}                state  - The currect state of the wizard machine.
 * @param {"PREV" | "NEXT" | "FINISH"} action - The action to check if it's possible to perform.
 *
 * @returns {boolean} Whether the action can be performed or not.
 */
function can(state, action) {
  return state.can(action);
}

/**
 * Receives the current state and returns a function that checks if the "NEXT" action can be performed
 * from the given state.
 *
 * @param {WizardState} state - The currect state of the wizard machine.
 *
 * @returns {() => boolean} A function that checks if the "NEXT" action can be performed.
 */
const canGoNext = state => () => can(state, 'NEXT');

/**
 * Receives the current state and returns a function that checks if the "PREV" action can be performed.
 * from the given state.
 *
 * @param {WizardState} state - The currect state of the wizard machine.
 *
 * @returns {() => boolean} A function that checks if the "PREV" action can be performed.
 */
const canGoBack = state => () => can(state, 'PREV');

/**
 * Receives the current state and returns a function that checks if the "FINISH" action can be performed.
 * from the given state.
 *
 * @param {WizardState} state - The currect state of the wizard machine.
 *
 * @returns {() => boolean} A function that checks if the "FINISH" action can be performed.
 */
const canGoFinish = state => () => can(state, 'FINISH');

/**
 * Receives the current state and returns a function that checks if the given step is the active step.
 *
 * @param {WizardState} state - The currect state of the wizard machine.
 * @param {string} step - The step to check if it's the active step.
 *
 * @returns {(step: string) => boolean} A function that checks if the given step is the active step.
 */
const isActiveStep = state => step => state.matches(step);

/**
 * Custom hook that creates a wizard state machine using
 * the 'machines/wizard/wizard.machine.js' module.
 *
 * @param {string}                 id    - The id of the wizard machine.
 * @param {number | Array<string>} steps - The number of steps or an array of steps names
 *
 * @returns {{
 *   activeStep: string;
 *   isActiveStep: (step: string) => boolean;
 *   goNext: () => void;
 *   goBack: () => void;
 *   goFinish: () => void;
 *   canGoNext: () => boolean;
 *   canGoBack: () => boolean;
 *   canGoFinish: () => boolean;
 * }}
 */
function useWizardMachine(id, steps) {
  const machine = useMemo(() => createWizardMachine(id, steps), [id, steps]);
  const [state, send] = useMachine(machine);

  const goFirst = () => send('RESET');
  const goNext = () => send('NEXT');
  const goBack = () => send('PREV');
  const goFinish = () => send('FINISH');

  const activeStep = state.value;

  return {
    activeStep,
    isActiveStep: isActiveStep(state),
    goNext,
    goBack,
    goFinish,
    goFirst,
    canGoNext: canGoNext(state),
    canGoBack: canGoBack(state),
    canGoFinish: canGoFinish(state),
  };
}

export default useWizardMachine;
