import PropTypes from 'prop-types';

import Indicator from './Indicator';

import {IcImg, IcFontSize, IcPen, IcCheck01} from '../../UI/Atoms/Icons';

// TODO: Add test, add documentation, add story

function Container({stepsNames}) {
  return (
    <div className="relative mx-auto flex h-10 w-[196px] flex-row-reverse items-center justify-between py-0.5 md:h-[2.68rem] md:w-[258px] md:max-w-[368px]">
      <Indicator
        value={stepsNames[2]}
        icon={<IcPen className="h-5 w-5 md:h-5 md:w-5" />}
        doneIcon={<IcCheck01 className="h-5 w-5 text-success-100" />}
      >
        Color
      </Indicator>
      <Indicator
        value={stepsNames[1]}
        icon={<IcFontSize className="h-5 w-5 md:h-5 md:w-5" />}
        doneIcon={<IcCheck01 className="h-5 w-5 text-success-100" />}
      >
        Fonts
      </Indicator>
      <Indicator
        value={stepsNames[0]}
        icon={<IcImg className="h-5 w-5 md:h-5 md:w-5" />}
        doneIcon={<IcCheck01 className="h-5 w-5 text-success-100" />}
      >
        Select logo
      </Indicator>
    </div>
  );
}
Container.propTypes = {
  stepsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Container;
