import PropTypes from 'prop-types';

import {WizardIndicator} from '../../UI/Organisms/Wizard';
import Text from '../../UI/Atoms/Typography/Text';

function Indicator({value, children, icon, doneIcon}) {
  return (
    <WizardIndicator
      value={value}
      className={`relative flex h-full w-5 flex-col items-center justify-between text-cold-gray-500 [&[data-active="true"]>*]:font-bold [&[data-active="true"]>*]:text-cold-gray-700 md:[&[data-active="true"]>*]:text-sm [&[data-active="true"]~*>*]:font-semibold [&[data-active="true"]~*>*]:text-success-500 [&[data-active="true"]~*>[data-done-icon="false"]]:hidden [&[data-active="true"]~*>[data-done-icon="true"]]:flex [&[data-active="true"]~*>div]:border-solid`}
    >
      <>
        <span data-done-icon="false" className="text-current">
          {icon}
        </span>
        <span
          data-done-icon="true"
          className="-mt-1 hidden h-5 w-5 items-center justify-center rounded-full bg-success-500 p-0.5 md:mt-0 md:p-0.5"
        >
          {doneIcon || icon}
        </span>
        <Text as="p" size="sm" weight="normal" className="whitespace-nowrap">
          {children}
        </Text>
        {value !== 'Color' && (
          <div className="absolute left-[calc(100%+16px)] top-[6px] w-[34px] border-b-2 border-dotted border-current md:top-2 md:w-[68px]" />
        )}
      </>
    </WizardIndicator>
  );
}
Indicator.defaultProps = {
  doneIcon: null,
};
Indicator.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  doneIcon: PropTypes.node,
};

export default Indicator;
