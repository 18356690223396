import PropTypes from 'prop-types';
import {createContext, useContext, useMemo, useState} from 'react';
import {useMachine} from '@xstate/react';

import createLogoMakerMachine from './LogoMaker.machine';

// TODO: Add tests, add documentation

const LogoMakerContext = createContext();

export function LogoMakerProvider({children}) {
  const logoMakerMachine = useMemo(() => createLogoMakerMachine(), []);
  const [state, send] = useMachine(logoMakerMachine);

  const [lastPreview, setLastPreview] = useState(state.context?.logo?.large_thumb);
  const [isMerchHidden, setIsMerchHidden] = useState(false);

  const value = useMemo(
    () => ({
      logo: state.context.logo,
      userCustomization: state.context.userCustomization,
      font: state.context.font,
      colors: state.context.colors,
      fetchingUiJSON: state.matches('fetchingLogoInfo'),
      lastSelectedPreview: lastPreview,
      activeStep: state.context.activeStep,
      stageLink: state.context.stageLink,
      isMerchHidden,

      getUiJSON: () => state.context.uiJSON,
      getStageLink: () => state.context.stageLink,
      getEditorLink: () => {
        const urlArray = state.context.stageLink?.split('?');
        if (urlArray && urlArray.length === 1) return `${state.context.stageLink}/editor`;
        if (urlArray && urlArray.length === 2) return `${urlArray[0]}/editor?${urlArray[1]}`;
      },
      setLogo: logo => {
        if (logo) {
          setLastPreview(logo.large_thumb);
          send({type: 'SELECT_LOGO', data: logo});
        } else {
          send({type: 'CLEAR_LOGO'});
        }
      },
      setSelectedPreview: preview => {
        setLastPreview(preview);
      },
      setFont: font => {
        // TODO: Implements clear font event
        if (font) {
          setLastPreview(font.large_thumb);
        }
        send({type: 'SELECT_FONT', data: font});
      },
      setColors: colors => {
        // TODO: Implements clear colors event
        if (colors) {
          setLastPreview(colors.large_thumb);
        }
        send({type: 'SELECT_COLORS', data: colors});
      },
      setChanges: changes => send({type: 'UPDATE_USER_CUSTOMIZATION', data: changes}),
      onSelectGridItem: () => {},
      setActiveStep: step => send({type: 'SET_ACTIVE_STEP', data: step}),

      // Merch visibility
      setIsMerchHidden: visible => setIsMerchHidden(visible),
    }),
    [state, send, lastPreview, isMerchHidden]
  );

  return <LogoMakerContext.Provider value={value}>{children}</LogoMakerContext.Provider>;
}

LogoMakerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useLogoMakerContext() {
  const context = useContext(LogoMakerContext);

  if (context === undefined) {
    throw new Error('useLogoMakerContext must be used within a LogoMakerProvider');
  }

  return context;
}
