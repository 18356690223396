import PropTypes from 'prop-types';

function LoadingSpinner({width, height}) {
  return (
    <div className="animate-spin">
      <svg
        width={width}
        height={height}
        viewBox="0 0 41 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5331 40.7538C25.7516 41.4511 26.4958 41.8431 27.1783 41.5822C30.0585 40.4809 32.6733 38.7709 34.841 36.5634C37.3182 34.0405 39.1349 30.9457 40.1303 27.553C41.1258 24.1603 41.2691 20.5745 40.5477 17.1132C39.8263 13.6519 38.2624 10.4219 35.9945 7.70937C33.7266 4.99683 30.8248 2.88547 27.546 1.5623C24.2672 0.239128 20.7127 -0.255006 17.1974 0.123667C13.682 0.502339 10.3143 1.74213 7.39251 3.73322C4.83583 5.47551 2.68968 7.74601 1.09563 10.3856C0.717895 11.0111 0.971885 11.813 1.61943 12.1515C2.26698 12.4901 3.06245 12.2367 3.44558 11.6145C4.83437 9.35903 6.68567 7.41707 8.88266 5.91989C11.4448 4.17386 14.3981 3.08666 17.4808 2.75459C20.5635 2.42253 23.6805 2.85584 26.5557 4.01616C29.4309 5.17648 31.9756 7.02799 33.9644 9.40667C35.9532 11.7854 37.3246 14.6178 37.9572 17.6531C38.5898 20.6884 38.4641 23.8329 37.5912 26.808C36.7183 29.7831 35.1252 32.4971 32.9529 34.7094C31.0902 36.6064 28.851 38.0844 26.3852 39.0517C25.7049 39.3186 25.3146 40.0565 25.5331 40.7538Z"
          fill="#317BD3"
        />
      </svg>
    </div>
  );
}

LoadingSpinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

LoadingSpinner.defaultProps = {
  width: 41,
  height: 42,
};

export default LoadingSpinner;
