import {useRef, useState, useCallback, useEffect} from 'react';
import {createMachine, assign, actions} from 'xstate';
import {useMachine} from '@xstate/react';

import getGraphics, {getGraphicImageUrl} from '../../../../lib/placeit/services/assets';
import {breakpoints} from '../../../../lib/constants';

import Dropdown from '../../../UI/Molecules/Dropdown';
import Input from '../../../UI/Molecules/Input';
import {IcChevronExpand, IcHide, IcSearch01} from '../../../UI/Atoms/Icons';
import Text from '../../../UI/Atoms/Typography/Text';
import Backdrop from '../Common/Backdrop';
import MobileDropDownHeader from '../Common/MobileDropDownHeader';

import LoadingSpinner from '../Common/LoadingSpinner';

import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import useWindowDimensions from '../../../../hooks/wizard/useWindowDimensions';
import useSelectLogoContext from './SelectLogo.context';

const {send, cancel} = actions;
const cancelNewQueryTimer = cancel('newQueryTimer');

let servedItems = 0;

async function moreItems(query, page) {
  if (page === 1) {
    servedItems = 0;
  }

  return getGraphics(query, page, 20).then(({hits, nbHits}) => {
    servedItems += 20;
    page += 1;

    return {
      items: hits.map(item => ({...item, imageUrl: getGraphicImageUrl(item.id)})),
      isThereMore: nbHits > servedItems,
    };
  });
}

// TODO: Maybe extract this machine to its own file.
// TODO: Add tests for this machine
const graphicsDropdownMachine = createMachine(
  {
    id: 'graphicsDropdown',
    initial: 'Initial',
    context: {
      items: [],
      page: 1,
      query: '',
      isThereMore: true,
    },
    states: {
      Initial: {
        entry: ['resetPage', 'resetThereIsMore'],
        invoke: {
          src: 'fetchItems',
          onDone: [
            {
              target: 'Items Available',
              cond: 'isThereMore',
              actions: ['assignItems', 'updateThereIsMore'],
            },
            {
              target: 'No More Items',
            },
          ],
        },
      },
      'Items Available': {
        on: {
          FETCH_MORE: {
            target: 'Fetching More',
            actions: 'increasePageByOne',
            cond: 'isThereMore',
          },
          NEW_QUERY: {
            actions: ['assignNewQuery', cancelNewQueryTimer, 'startNewQueryTimer'],
          },
          NEW_QUERY_TIMER: {
            target: 'Initial',
            actions: ['resetItems'],
          },
        },
      },
      'Fetching More': {
        invoke: {
          src: 'fetchItems',
          onDone: [
            {
              target: 'Fetch Done',
              actions: ['updateItems', 'updateThereIsMore'],
            },
          ],
        },
      },
      'No More Items': {
        on: {
          NEW_QUERY: {
            actions: ['assignNewQuery', cancelNewQueryTimer, 'startNewQueryTimer'],
          },
          NEW_QUERY_TIMER: {
            target: 'Initial',
            actions: ['resetItems'],
          },
        },
      },
      'Fetch Done': {
        always: [
          {
            target: 'Items Available',
            cond: 'isThereMore',
          },
          {
            target: 'No More Items',
          },
        ],
      },
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      assignItems: assign({
        items: (_, event) => event.data.items,
      }),
      assignNewQuery: assign({
        query: (_, event) => event.data.query,
      }),
      increasePageByOne: assign({
        page: context => context.page + 1,
      }),
      resetPage: assign({
        page: 1,
      }),
      resetItems: assign({
        items: [],
      }),
      updateItems: assign({
        items: (context, event) => [...context.items, ...event.data.items],
      }),
      updateThereIsMore: assign({
        isThereMore: (_, event) => event.data.isThereMore,
      }),
      resetThereIsMore: assign({
        isThereMore: true,
      }),
      startNewQueryTimer: send('NEW_QUERY_TIMER', {delay: 800, id: 'newQueryTimer'}),
    },
    guards: {
      isThereMore: context => context.isThereMore,
    },
  }
);

// TODO: Maybe extract this hook to its own file.
// TODO: Add tests for this hook
const useGraphicsDropdownMachine = () => {
  const [state, sendAction] = useMachine(graphicsDropdownMachine, {
    services: {
      fetchItems: context => moreItems(context.query, context.page),
    },
  });

  const setNewQuery = useCallback(newQuery => {
    sendAction({type: 'NEW_QUERY', data: {query: newQuery}});
  }, []);

  const fetchMore = useCallback(() => {
    sendAction({type: 'FETCH_MORE'});
  }, []);

  const fetching = state.matches('Fetching More') || state.matches('Initial');
  const enableFetchOnScroll = state.matches('Items Available');

  return {
    fetching,
    items: state.context.items,
    query: state.context.query,
    enableFetchOnScroll,
    setNewQuery,
    fetchMore,
  };
};

export default function GraphicSelector() {
  const contentRef = useRef(null);
  const triggerRef = useRef(null);

  const {fetching, items, query, enableFetchOnScroll, setNewQuery, fetchMore} =
    useGraphicsDropdownMachine();

  const [firstTimeOpen, setFirstTimeOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const screen = useWindowDimensions();
  const {selectedGraphic, setSelectedGraphic} = useSelectLogoContext();

  const infiniteScrollRef = useRef(null);

  useIntersectionObserver(
    firstTimeOpen ? infiniteScrollRef : {},
    {root: contentRef.current},
    intersecting => {
      intersecting && fetchMore();
    }
  );

  // TODO: move these variables inside the function that will use them
  const selectedExcluded = items.filter(item => {
    if (item.id === selectedGraphic.id) {
      return false;
    }
    return item;
  });
  let itemsToRender = [selectedGraphic, ...selectedExcluded];
  if (selectedGraphic.id === 'fake' || selectedGraphic.id === 'no image') {
    itemsToRender = [...selectedExcluded];
  }

  // TODO: move this outside the component
  const renderNoImageItem = useCallback(() => {
    return (
      <Dropdown.Item
        key="no image"
        onSelect={() => setSelectedGraphic({id: 'no image'})}
        className="h-auto w-auto cursor-pointer outline-none hover:ring-2 hover:ring-cold-gray-600 focus:ring-2 focus:ring-cold-gray-600"
      >
        <div
          className={`flex h-full w-full flex-col items-center justify-center gap-1 overflow-hidden border-1 border-cold-gray-500 bg-gray-100 md:h-[68px] md:w-[68px] ${
            selectedGraphic.id === 'no image' ? 'border-2 border-cold-gray-600' : ''
          }`}
        >
          <IcHide className="h-5 w-5 fill-gray-400" />
          <Text className="text-center text-xxs font-normal text-gray-600">No Image</Text>
        </div>
      </Dropdown.Item>
    );
  });

  // TODO: move this outside the component
  const renderItems = useCallback(() => {
    return itemsToRender.map(item => (
      <Dropdown.Item
        key={item.id}
        onSelect={() => setSelectedGraphic(item)}
        className="h-auto w-auto cursor-pointer outline-none hover:ring-2 hover:ring-cold-gray-600 focus:ring-2 focus:ring-cold-gray-600"
      >
        <div
          className={`flex h-full w-full items-center justify-center overflow-hidden border-1 border-cold-gray-400 md:h-[68px] md:w-[68px] ${
            selectedGraphic.id === item.id ? 'border-2 border-cold-gray-600' : ''
          }`}
        >
          <img
            className="aspect-square h-full w-full object-cover grayscale"
            src={item.imageUrl}
            alt={item.name}
          />
        </div>
      </Dropdown.Item>
    ));
  });

  const onOpen = open => {
    setIsOpen(open);
    setFirstTimeOpen(open);
  };

  useEffect(() => {
    if (screen.width < breakpoints.md && isOpen) {
      const triggerPosition = triggerRef.current.getBoundingClientRect();

      contentRef.current.style.top = `${screen.height * 0.2 - triggerPosition.y}px`;
      contentRef.current.style.right = `${screen.width}px`;
      contentRef.current.style.left = `-${triggerPosition.x}px`;
    }
  }, [isOpen, screen.width]);

  return (
    <Dropdown.Root onOpenChange={onOpen}>
      <Dropdown.Trigger
        text="Choose Graphic"
        className="h-9 text-base"
        icon={<IcChevronExpand className="h-4 w-4" />}
        ref={triggerRef}
      />
      {isOpen && screen.width < breakpoints.md && <Backdrop />}
      <Dropdown.Content portal className="relative z-40 md:top-1">
        <div
          className="absolute flex h-[75vh] min-h-min w-screen animate-slideUp flex-col space-y-4 overflow-hidden rounded-t-md bg-white shadow-md md:relative md:max-h-[404px] md:w-auto md:animate-dropDown md:overflow-hidden md:rounded-md"
          ref={contentRef}
        >
          <div className="m-4 mb-0">
            {screen.width < breakpoints.md && <MobileDropDownHeader title="Choose Graphic" />}
            <Dropdown.Item onSelect={e => e.preventDefault()} asChild>
              <Input
                placeholder="Search graphics"
                color="blue"
                size="md"
                className="h-9 font-source-sans-pro md:text-sm"
                leftIcon={<IcSearch01 className="h-5 w-5" />}
                value={query}
                onChange={e => setNewQuery(e.target.value)}
                onKeyDown={e => {
                  // Space is onw of the keys defined by the Radix Dropdown component
                  // as a key to close the dropdown and select the current item.
                  // We don't want that to happen when the user is typing a query.
                  if (e.code === 'Space') {
                    setNewQuery(`${query} `);
                  }
                  e.stopPropagation();
                }}
              />
            </Dropdown.Item>
          </div>
          <div className="grid h-[57vh] min-w-full grid-cols-4 gap-4 overflow-x-hidden overflow-y-scroll p-4 pt-0.5 md:h-full md:grid-cols-5">
            {renderNoImageItem()}
            {renderItems()}
            {items.length === 0 && (
              <div className="col-span-full mt-10 flex h-full w-full flex-col items-center justify-center gap-1">
                <Text size="md" className="text-gray-700">
                  Sorry, couldn’t find what you are looking for
                </Text>
                <Text size="sm" className="text-gray-700">
                  Try again with a different search term.
                </Text>
              </div>
            )}
            <div className="relative col-span-full flex h-16 w-full items-center justify-center py-4">
              <div
                ref={infiniteScrollRef}
                className={`absolute inset-0 ${
                  enableFetchOnScroll ? 'block' : 'hidden'
                } h-full w-full`}
              />

              {fetching && <LoadingSpinner />}
            </div>
          </div>
        </div>
      </Dropdown.Content>
    </Dropdown.Root>
  );
}
