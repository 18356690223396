import PropTypes from 'prop-types';
import {useRef} from 'react';

import * as Select from '@radix-ui/react-select';
import {IcChevronExpand, IcCheck01} from '../../../UI/Atoms/Icons';
import Text from '../../../UI/Atoms/Typography/Text';

import useSelectLogoContext from './SelectLogo.context';

function BaseItem({sortType, label, className, ...props}) {
  return (
    <Select.Item
      key={sortType}
      value={sortType}
      className={`flex items-center justify-between gap-x-2 px-3 py-2 outline-none hover:cursor-pointer [&[data-highlighted]]:bg-cold-gray-200 ${className}`}
      {...props}
    >
      <Select.ItemText>
        <Text size="md" className="text-cold-gray-700">
          {label}
        </Text>
      </Select.ItemText>
      <Select.ItemIndicator>
        <IcCheck01 className="h-3.5 w-3.5 text-cold-gray-700" />
      </Select.ItemIndicator>
    </Select.Item>
  );
}

BaseItem.defaultProps = {
  className: '',
};

BaseItem.propTypes = {
  sortType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

function SortBy() {
  const {sortBy, setSortBy} = useSelectLogoContext();
  const triggerRef = useRef(null);
  const contentRef = useRef(null);

  // When open, make the content apper below the trigger aligned to the left
  const handleOpenChange = open => {
    if (!open) return;

    const triggerPosition = triggerRef.current.getBoundingClientRect();
    const triggerX = triggerPosition.x;
    const triggerY = triggerPosition.y;
    const triggerHeight = triggerPosition.height;

    // Set content bellow trigger
    contentRef.current.style.top = `${triggerY + triggerHeight + 4}px`;
    contentRef.current.style.left = `${triggerX}px`;
  };

  const handleSortByChange = value => {
    setSortBy(value);
  };

  return (
    <div className="flex items-center gap-x-2">
      <Text size="md" weight="semibold" className="hidden w-12 md:inline-block">
        Sort by
      </Text>
      <Select.Root
        defaultValue={sortBy}
        onOpenChange={handleOpenChange}
        onValueChange={handleSortByChange}
      >
        <Select.Trigger
          ref={triggerRef}
          className="bg-transaprent flex h-full w-auto items-center justify-between gap-x-1 rounded-md border-1 border-transparent px-3 py-2 text-right outline-none hover:border-cold-gray-600 hover:bg-cold-gray-200 focus:border-cold-gray-800 focus:bg-cold-gray-200 [&[data-state='open']_#trigger-icon]:rotate-180"
        >
          <Select.Value className="font-semibold text-cold-gray-700" />
          <Select.Icon
            id="trigger-icon"
            className="origin-center transition-transform duration-300"
          >
            <IcChevronExpand className="h-4 w-4" />
          </Select.Icon>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content
            ref={contentRef}
            className="fixed z-10 rounded-md border-1 bg-white py-4 drop-shadow-lg"
          >
            <Select.Viewport>
              <BaseItem sortType="best" label="Best selling" />
              <BaseItem sortType="new" label="Newest" />
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}

export default SortBy;
