import PropTypes from 'prop-types';
import {useMemo, forwardRef} from 'react';

const INPUT_STYLES = {
  gray: 'border-cold-gray-400 focus:border-cold-gray-800 active:border-cold-gray-800 hover:border-cold-gray-600 invalid:border-danger-500 disabled:border-gray-300 disabled:bg-gray-100 outline-none text-gray-900',
  blue: 'border-cold-gray-400 focus:border-cold-gray-800 active:border-cold-gray-800 hover:border-cold-gray-600 invalid:border-danger-500 disabled:border-cold-gray-400 disabled:bg-cold-gray-100 outline-none text-cold-gray-900',
};

export const COLORS = {
  gray: 'text-gray-800',
  blue: 'text-cold-gray-700',
};

export const DISABLED_COLORS = {
  gray: 'text-gray-300',
  blue: 'text-cold-gray-400',
};

export const SIZES = {
  sm: {
    label: 'text-xs',
    input: 'text-sm py-2 px-3',
  },
  md: {
    label: 'text-sm',
    input: 'text-base py-2 px-3',
  },
  lg: {
    label: 'text-base',
    input: 'text-lg py-3 px-3',
  },
};

const Input = forwardRef(
  ({size, color, label, helperText, leftIcon, rightIcon, className, ...args}, ref) => {
    const {disabled, id} = args;

    const {input: inputSize, label: labelStyles} = useMemo(() => SIZES[size] || SIZES.md, [size]);
    const inputStyle = useMemo(() => INPUT_STYLES[color] || INPUT_STYLES.gray, [color]);
    const labelColor = useMemo(
      () =>
        disabled ? DISABLED_COLORS[color] || DISABLED_COLORS.gray : COLORS[color] || COLORS.gray,
      [color, disabled]
    );

    return (
      <>
        {label && (
          <label
            htmlFor={id || ''}
            className={`font-source-sans-pro ${labelStyles} ${labelColor}`}
            data-testid="input-label"
          >
            {label}
          </label>
        )}
        <span className="relative flex flex-col-reverse">
          {leftIcon && (
            <span
              data-testid="left-icon-container"
              className="absolute left-2 flex h-full w-7 items-center overflow-hidden"
            >
              {leftIcon}
            </span>
          )}
          {rightIcon && (
            <span
              data-testid="right-icon-container"
              className="absolute right-1 flex h-full w-7 items-center overflow-hidden"
            >
              {rightIcon}
            </span>
          )}
          <input
            ref={ref}
            className={`font-source-sans-pro ${leftIcon && 'pl-8'} ${
              rightIcon && 'pr-8'
            } rounded-md border
              ${inputSize || ''}
              ${inputStyle || ''}
              ${className || ''}`}
            {...args}
          />
        </span>
        {helperText && (
          <span
            className={`font-source-sans-pro ${labelStyles} ${labelColor}`}
            data-testid="helper-text"
          >
            {helperText}
          </span>
        )}
      </>
    );
  }
);
Input.displayName = 'Input';

Input.propTypes = {
  /**
   * The available size variants for the Input:
   */
  size: PropTypes.oneOf(Object.keys(SIZES)),
  /**
   * The colors options can be either gray or blue
   */
  color: PropTypes.oneOf(Object.keys(COLORS)),
  /**
   * Label text for the input
   */
  label: PropTypes.string,
  /**
   * The helper text to show below the input
   */
  helperText: PropTypes.string,
  /**
   *  Accepts a React element to render inside the input field on the left side
   */
  leftIcon: PropTypes.element,
  /**
   *  Accepts a React element to render inside the input field on the right side
   */
  rightIcon: PropTypes.element,
  /**
   * The optional CSS class to be applied on the input
   */
  className: PropTypes.string,
};

Input.defaultProps = {
  size: 'md',
  color: 'gray',
  label: '',
  helperText: '',
  leftIcon: null,
  rightIcon: null,
  className: '',
};

export default Input;
