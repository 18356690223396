import PropTypes from 'prop-types';
import {useEffect, memo} from 'react';

import useLogoMakerContext from '../../LogoMaker.context';
import useSelectLogoContext from './SelectLogo.context';

import StepContainer from '../Common/StepContainer';
import FinishButton from '../Common/FinishButton';
import NextButton from '../Common/NextButton';

import StepHeader from './StepHeader';
import SelectLogoGrid from './SelectLogoGrid';

import {trackEvent, MOVE_TO_FONTS} from '../../../../lib/analytics';

function SelectLogo({value}) {
  const {setLogo, fetchingUiJSON, setChanges, getEditorLink} = useLogoMakerContext();
  const {selectedLogo, companyName, selectedGraphic, selectedIndustry} = useSelectLogoContext();

  useEffect(() => {
    if (selectedLogo) {
      setLogo(selectedLogo);
    } else {
      setLogo(null);
    }
  }, [selectedLogo]);

  useEffect(() => {
    setChanges({companyName, graphicId: selectedGraphic, industryId: selectedIndustry?.id});
  }, [companyName, selectedGraphic]);

  const handleClickOnNext = () => {
    trackEvent(MOVE_TO_FONTS, {});
  };

  const stepButtons = () => {
    return {
      finish: (
        <FinishButton disabled={!selectedLogo || fetchingUiJSON} logoLink={getEditorLink()} />
      ),
      next: (
        <NextButton disabled={!selectedLogo || fetchingUiJSON} onClick={handleClickOnNext}>
          Next: Fonts
        </NextButton>
      ),
    };
  };

  return (
    <StepContainer value={value} buttons={stepButtons()}>
      <StepHeader />
      <SelectLogoGrid />
    </StepContainer>
  );
}

SelectLogo.propTypes = {
  value: PropTypes.string.isRequired,
};

export default memo(SelectLogo);
