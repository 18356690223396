import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import * as Portal from '@radix-ui/react-portal';

import {IcCloseSm} from '../../UI/Atoms/Icons';

const arrowStyles = {
  right:
    "after:absolute after:right-[-10px] after:top-1/2 after:block after:translate-y-[-50%] after:border-y-[10px] after:border-l-[10px] after:border-y-transparent after:border-l-cold-gray-800 after:content-['']",
};

function onResizeHandler(callback) {
  let resizeTimeout;
  const handleResize = () => {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(callback, 150);
  };
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}

export default function TooltipNotification({
  target,
  children,
  className,
  onClose,
  open,
  ...props
}) {
  const [targetBounds, setTargetBounds] = useState({x: 0, y: 0, height: 0});
  const [openState, setOpenState] = useState(open);

  useEffect(() => {
    onResizeHandler(() => {
      if (target.current) {
        setTargetBounds(target.current.getBoundingClientRect());
      }
    });
  }, [target.current]);
  useEffect(() => {
    setOpenState(open);
    if (open && target.current) {
      setTargetBounds(target.current.getBoundingClientRect());
    }
  }, [open]);

  const {x, y, height} = targetBounds;
  const handleClose = () => {
    window.localStorage.setItem('merchNotification', 'true');
    setOpenState(false);
    onClose();
  };

  const itShouldRender = openState && window.localStorage.getItem('merchNotification') !== 'true';

  return itShouldRender ? (
    <Portal.Root>
      <div
        {...props}
        className={`absolute z-50 hidden w-52 translate-y-[-50%] rounded bg-cold-gray-800 py-3 pl-6 pr-8 text-white shadow-xs md:block ${
          arrowStyles.right
        } ${className ?? ''} ${openState ? 'opacity-1 animate-fadeIn' : ''}`}
        style={{top: `${y + window.scrollY + height / 2}px`, left: `${x - 208}px`}}
      >
        <div>{children}</div>
        <button onClick={handleClose} type="button" className="absolute right-2 top-3 ">
          <IcCloseSm className="h-5 w-5 cursor-pointer fill-white" />
        </button>
      </div>
    </Portal.Root>
  ) : null;
}
TooltipNotification.propTypes = {
  target: PropTypes.shape({
    current: PropTypes.shape({
      getBoundingClientRect: PropTypes.func,
    }),
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

TooltipNotification.defaultProps = {
  target: null,
  children: null,
  className: '',
  onClose: () => {},
};
