import {memo} from 'react';
import PropTypes from 'prop-types';
import {WizardStep} from '../../../UI/Organisms/Wizard';
import useLogoMakerContext from '../../LogoMaker.context';

// TODO: Add test, add documentation, add story

const notHiddenStyle = '[&:not([hidden])]:block lg:[&:not([hidden])]:translate-x-0';

function StepContainer({value, children, buttons}) {
  const {logo, setActiveStep} = useLogoMakerContext();

  const onActive = () => {
    setActiveStep(value);
  };

  return (
    <WizardStep
      className={`h-full w-full lg:isolate ${notHiddenStyle} inset-0 lg:translate-x-full`}
      value={value}
      onActive={onActive}
    >
      <div className="flex h-full w-full flex-col">
        <div
          className={`${
            logo ? 'lg:pr-[230px]' : ''
          } relative flex flex-1 flex-col overflow-auto scroll-smooth px-4 py-2 lg:w-full lg:px-6 lg:pt-8`}
        >
          {children}
        </div>
        <div className="z-20 flex h-[4.25rem] items-center justify-center gap-2.5 border-t-[1px] border-t-cold-gray-200 bg-white p-2.5 lg:z-auto lg:bg-inherit">
          {buttons.back}
          {buttons.finish}
          {buttons.next}
        </div>
      </div>
    </WizardStep>
  );
}

StepContainer.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.shape({
    back: PropTypes.node,
    finish: PropTypes.node,
    next: PropTypes.node,
  }).isRequired,
};

export default memo(StepContainer);
