
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/logo-maker/[[...industry]]",
      function () {
        return require("private-next-pages/logo-maker/[[...industry]].jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/logo-maker/[[...industry]]"])
      });
    }
  