import {useState, useEffect, useRef} from 'react';
import useIntersectionObserver from './useIntersectionObserver';

function useRequestOnScroll(fetchFn) {
  const [observerTarget, setObserverTarget] = useState({});

  const requestMoreOnScroll = intersecting => {
    intersecting && fetchFn();
  };

  const infiniteScrollRef = useRef(null);
  // useIntersectionObserver(infiniteScrollRef, {}, requestMoreOnScroll);
  useIntersectionObserver(observerTarget, {}, requestMoreOnScroll);

  useEffect(() => {
    setObserverTarget({
      current: infiniteScrollRef.current,
    });
  }, [infiniteScrollRef.current]);

  return infiniteScrollRef;
}

export default useRequestOnScroll;
