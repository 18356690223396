import * as Portal from '@radix-ui/react-portal';

export default function Backdrop() {
  return (
    <Portal.Root>
      <div className="absolute bottom-0 left-0 right-0 top-0 z-30 h-screen w-screen bg-black opacity-70">
        &nbsp;
      </div>
    </Portal.Root>
  );
}
