import Trigger from './Trigger';
import Content from './Content';
import Item from './Item';
import Root from './Root';

const Dropdown = {
  Root,
  Trigger,
  Content,
  Item,
};

export default Dropdown;

export {Root, Trigger, Content, Item};
