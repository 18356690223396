import PropTypes from 'prop-types';
import {WizardNext} from '../../../UI/Organisms/Wizard';
import BaseButton from '../../../UI/Molecules/BaseButton';

import {IcChevronRight} from '../../../UI/Atoms/Icons';

function NextButton({children, disabled, className, ...args}) {
  return (
    <WizardNext disabled={disabled} asChild>
      <BaseButton
        {...args}
        className={`grow-[0.5] text-sm disabled:cursor-not-allowed md:min-w-[155px] lg:grow-0 lg:text-base ${className}}`}
        rightIcon={<IcChevronRight className="h-5 w-5" />}
      >
        {children}
      </BaseButton>
    </WizardNext>
  );
}

NextButton.defaultProps = {
  disabled: false,
  children: 'Next',
  className: '',
};

NextButton.propTypes = {
  disabled: PropTypes.bool,
  children: BaseButton.propTypes.children,
  className: PropTypes.string,
};

export default NextButton;
