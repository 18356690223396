import {stageSearch} from '../../algolia/client';

// TODO: Revisit this file before deployment to production

// TODO: The name of the index in Algolia should be read from the environment var

// Staging 1
// const LOGO_MAKER_INDEX = client.initIndex('Stage_staging_1_alternate');

// Production
const LOGO_MAKER_INDEX = 'Stage_production_alternate';
const LOGO_MAKER_INDEX_NEWEST = 'Stage_production_alternate_replica_newest';

const DEFAULT_NUMERIC_FILTERS = ['is_published=1', 'category_id=8', 'is_list=0'];

const getLogosFnBuilder = index => (page, hitsPerPage, industry, query) => {
  const searchConfig = {
    query,
    originalPage: page,
    indexName: index,
    hitsPerPage,
    numericFilters: [...DEFAULT_NUMERIC_FILTERS],
    filters: industry ? `(stage_tags:'${industry}')` : '',
  };

  return stageSearch(searchConfig).then(results => {
    const {nbHits} = results;

    // Found at least 1 result, return it
    if (nbHits !== 0) {
      return results;
    }

    // No results found, but the query is a single word, returns the empty results
    const hasQueryMultipleWords = query.split(' ').length > 1;
    if (!hasQueryMultipleWords) {
      return results;
    }

    // Tall algolia to use all words in the query as optional words and search for prefix
    const searchConfigWithPrefix = {
      ...searchConfig,
      optionalWords: query,
      // This option is not recommended, I'm using it for compatibility with the
      // legacy app. See here: https://www.algolia.com/doc/api-reference/api-parameters/queryType/
      queryType: 'prefixAll',
    };
    return stageSearch(searchConfigWithPrefix);
  });
};

export const getLogos = getLogosFnBuilder(LOGO_MAKER_INDEX);
export const getLogosNewest = getLogosFnBuilder(LOGO_MAKER_INDEX_NEWEST);

const INIT_AMOUNT_OF_ITEMS = 12;
const NEXT_AMOUNT_OF_ITEMS = 12;

export const getGridItems = (startingPage, startingIndustry) => {
  let internalPage = startingPage;
  let internalIndustryFTag = startingIndustry ? startingIndustry.fTag : '';
  let internalQuery = '';
  let internalSortBy = 'best';

  return async (industry, sortBy, query) => {
    if (
      industry.fTag !== internalIndustryFTag ||
      sortBy !== internalSortBy ||
      query !== internalQuery
    ) {
      internalIndustryFTag = industry.fTag;
      internalQuery = query;
      internalSortBy = sortBy;
      internalPage = 1;
    }

    const page = internalPage;
    const hitsPerPage = internalPage === 1 ? INIT_AMOUNT_OF_ITEMS : NEXT_AMOUNT_OF_ITEMS;
    const fetchLogos = sortBy === 'new' ? getLogosNewest : getLogos;

    const algoliaRes = await fetchLogos(page, hitsPerPage, internalIndustryFTag, query);

    internalPage += 1;

    let isThereMore = algoliaRes.nbHits > page * hitsPerPage;

    if (algoliaRes.nbHits === 0) {
      isThereMore = true;
    }

    return {
      items: algoliaRes.hits,
      isThereMore,
    };
  };
};

export default {getLogos, getLogosNewest};
