import {createMachine, assign} from 'xstate';

import asgard from '../../lib/placeit/services/asgard';
import {trackEvent, SELECT_LOGO, SELECT_FONT, SELECT_COLOR} from '../../lib/analytics';
import StageLinkBuilder from '../../lib/placeit/stage_link_builder';
import {DEFAULT_CUSTOMIZATION} from '../../lib/placeit/services/industries';

// TODO: Add test, add documentation

const createLogoMakerMachine = () =>
  createMachine(
    {
      id: 'LogoMaker',
      preserveActionOrder: true,
      predictableActionArguments: true,
      initial: 'idle',
      context: {
        activeStep: 'Logo', // valid step values: Logo, Font, Color
        logo: null,
        font: null,
        colors: null,
        uiJSON: null,
        stageLink: '',
        userCustomization: DEFAULT_CUSTOMIZATION,
      },
      states: {
        idle: {
          on: {
            SELECT_LOGO: {
              actions: [
                'assignLogoToContext',
                'clearFont',
                'clearColors',
                'clearUiJSON',
                'generateStageLink',
                'trackEventSelectLogo',
              ],
              target: 'fetchingLogoInfo',
            },
            CLEAR_LOGO: {
              actions: ['clearLogo', 'clearFont', 'clearColors', 'clearUiJSON', 'clearStageLink'],
            },
            UPDATE_USER_CUSTOMIZATION: {
              actions: ['assignUserCustomizationToContext', 'generateStageLink'],
            },
            SELECT_FONT: {
              actions: [
                'assignFontToContext',
                'clearColors',
                'generateStageLink',
                'trackEventSelectFont',
              ],
              cond: 'hasLogo',
            },
            SELECT_COLORS: {
              actions: ['assignColorsToContext', 'generateStageLink', 'trackEventSelectColor'],
              cond: 'hasFont',
            },
            SET_ACTIVE_STEP: {
              actions: ['assignActiveStepToContext', 'generateStageLink'],
            },
          },
        },
        fetchingLogoInfo: {
          invoke: {
            src: 'fetchLogoInfo',
            onDone: {
              target: 'idle',
              actions: ['assignUiJsonToContext', 'generateStageLink'],
            },
            onError: {
              actions: 'logError',
            },
          },
        },
      },
    },
    {
      guards: {
        hasLogo: ctx => ctx.logo !== null,
        hasFont: ctx => ctx.font !== null,
      },
      actions: {
        // Stage link generation
        generateStageLink: assign({
          stageLink: ctx => {
            if (!ctx.logo) return null; // No logo selected yet
            if (!ctx.uiJSON) return ctx.logo.stage_link; // No UI JSON yet

            let font = ctx.font?.name;
            let {colors} = ctx;

            if (ctx.activeStep === 'Logo') {
              font = null;
              colors = null;
            }

            if (ctx.activeStep === 'Font') {
              colors = null;
            }

            // Both logo and UI JSON are available
            return new StageLinkBuilder(
              ctx.logo,
              ctx.uiJSON,
              font,
              colors,
              ctx.userCustomization
            ).build();
          },
        }),
        clearStageLink: assign({
          stageLink: () => null,
        }),

        // Logo related
        assignLogoToContext: assign({
          logo: (_, event) => event.data,
        }),
        clearLogo: assign({
          logo: () => null,
        }),

        // Font related
        assignFontToContext: assign({
          font: (_, event) => event.data,
        }),
        clearFont: assign({
          font: () => null,
        }),

        // Colors related
        assignColorsToContext: assign({
          colors: (_, event) => event.data,
        }),
        clearColors: assign({
          colors: () => null,
        }),

        // UI JSON related
        assignUiJsonToContext: assign({
          uiJSON: (_, event) => {
            return event.data;
          },
        }),
        clearUiJSON: assign({
          uiJSON: () => null,
        }),

        // User customization related
        assignUserCustomizationToContext: assign({
          userCustomization: (_, event) => event.data,
        }),
        assignActiveStepToContext: assign({
          activeStep: (_, event) => event.data,
        }),

        // Error logging
        logError: (_, event) => {
          console.error(event.data);
        },

        // Tracking
        trackEventSelectLogo: (_, event) => {
          if (event.data) {
            trackEvent(SELECT_LOGO, {position: event.data.grid_position});
          }
        },
        trackEventSelectFont: (_, event) => {
          if (event.data) {
            trackEvent(SELECT_FONT, {position: event.data?.grid_position});
          }
        },
        trackEventSelectColor: (_, event) => {
          if (event.data) {
            trackEvent(SELECT_COLOR, {position: event.data.grid_position});
          }
        },
      },
      services: {
        fetchLogoInfo: async ctx => {
          const id = ctx.logo.smart_template_id;
          const presetId = ctx.logo.smart_template_preset_id;

          return asgard.fetchMergedRecords({
            id,
            preset: presetId,
          });
        },
      },
    }
  );

export default createLogoMakerMachine;
