import {memo, useState, useEffect, useCallback} from 'react';

import useSelectLogoContext from './SelectLogo.context';

import debounce from '../../../../lib/utils/debounce';

import TextUIKit from '../../../UI/Atoms/Typography/Text';
import Input from '../../../UI/Molecules/Input';

import IndustrySelector from './IndustrySelector';
import GraphicSelector from './GraphicSelector';

const Text = memo(TextUIKit);

const headerTitle = industry => {
  const {isDefault, name} = industry;
  return isDefault ? `Make a professional logo!` : `Make a professional ${name} logo!`;
};

export default function StepHeader() {
  const {selectedIndustry, companyName, setCompanyName, minimalUI} = useSelectLogoContext();

  const [privateCompanyName, setPrivateCompanyName] = useState(companyName);
  const deboucedSetCompanyName = useCallback(debounce(setCompanyName, 800), [setCompanyName]);

  useEffect(() => {
    // Update the internal (private) value only if the external value changes
    if (companyName.trim() !== privateCompanyName.trim()) {
      setPrivateCompanyName(companyName);
    }
  }, [companyName]);

  useEffect(() => {
    // TODO: This implementation requires improvements
    if (companyName.trim() !== privateCompanyName.trim()) {
      deboucedSetCompanyName(privateCompanyName);
    }
  }, [privateCompanyName]);

  const updateCompanyName = e => {
    // Set the value of the input to the state.
    // Trim the value to remove any extra spaces.
    // But, allow the user to delete the value.
    if (e.target.value || e.target.value === '') {
      setPrivateCompanyName(e.target.value.trimStart());
    }
  };

  return (
    <div
      className={`grid grid-cols-2 md:mx-auto  ${
        minimalUI ? 'grid-rows-1 md:w-full md:max-w-[1041px] lg:w-5/6' : 'grid-rows-3'
      } gap-2 md:grid-cols-3 md:gap-4`}
    >
      {!minimalUI && (
        <Text
          size="lg"
          weight="semibold"
          as="h1"
          className="col-span-2  md:col-span-3 md:mx-auto md:block md:text-[2rem] md:font-bold"
        >
          {headerTitle(selectedIndustry)}
        </Text>
      )}

      <div className="col-span-2 md:col-span-1">
        <Input
          className="h-9 md:text-sm"
          type="text"
          size="md"
          placeholder="Company Name"
          value={privateCompanyName}
          onChange={updateCompanyName}
          onBlur={() => setPrivateCompanyName(privateCompanyName.trim())}
        />
      </div>

      <IndustrySelector />
      <GraphicSelector />

      {!minimalUI && (
        <Text
          weight="semibold"
          size="lg"
          className="col-span-3 hidden max-w-4xl justify-self-center text-center text-gray-400 md:block"
        >
          {selectedIndustry
            ? selectedIndustry.description
            : "Placeit's logo maker lets you quickly and easily create stunning logos in seconds, choose from thousands of incredible logo templates and easily edit them. You don't need to hire an expensive designer to make the perfect logo for your business or team."}
        </Text>
      )}
    </div>
  );
}
