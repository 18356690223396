import algoliaClient from '../../algolia/client';

// TODO: Read this from env
const ASSETS_INDEX = 'nice_assets_index_by_tag_replica';

const index = algoliaClient.initIndex(ASSETS_INDEX);

function getGraphics(query, page, perPage) {
  const filters = 'tags:SVG/NP_all_Logos OR tags:PSD_All_Logos AND NOT type:noun-asset';
  return index.search(query, {
    page: page - 1,
    hitsPerPage: perPage,
    filters,
  });
}

// TODO: The URL template here should depend on the environment
export const getGraphicImageUrl = id =>
  `https://assets-3.placeit.net/image_library/${id}/images/small.jpg`;

export default getGraphics;
