import {useState, useEffect} from 'react';
import {getInitialIndustries, getIndustries} from '../lib/placeit/services/industries';

function useIndustries(refetch = false) {
  const [industries, setIndustries] = useState(getInitialIndustries());

  useEffect(() => {
    getIndustries('', refetch).then(setIndustries);
  }, [refetch]);

  return {
    industries,
  };
}

export default useIndustries;
